import {BASE_PATH, API_VERSION} from '../config/config';

const bearerTkn = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiZW1haWwiOiJyb290QGltYmVyYS5jb20iLCJjdXN0b21lcl9pZCI6ImltYmVyYSIsInJvbGUiOjF9.UPi4kiZ7FjoibRDXL0ymSLmLS-qlO5-S_oUX7nvRqHw"

//Register user
export async function signinApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data);
    const response = await fetch(url, params)
    return response.json()
}

//Register user cirwifi
export async function signinCirWifiApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/users`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Autorization": "Bearer "+ bearerTkn
        },
        redirect: 'follow'
    };
    // console.log(data);
    const response = await fetch(url, params)
    return response.text()
}

//Login user
export async function loginApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/login/${data.userId}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data.input),
        headers: {
            "Content-Type": "application/json",
        }
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        // console.log(result);
        return result;
    })
    .catch(err => {
        return err.message;
    });

}

//Login user Cir Wifi Api
export async function loginCirWifiApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/login`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Autorization": "Bearer " + bearerTkn
        }
    };

    const response = await fetch(url, params)
    return await response.text();
}

//Login user
export async function loginWithPhoneApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/logInWithPhone`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        // console.log(result);
        return result;
    })
    .catch(err => {
        return err.message;
    });

}

export async function findUserByPhoneOrEmail(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/${data.data}`;
    const params = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data);
    const response = await fetch(url, params)
    return response.json();
}

export async function findUserByIdApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/${data}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data);
    const response = await fetch(url, params)
    return await response.json();
}

export async function updateUser2faApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/2fa-update`;
    const bodyObj = {
        userId: data.userId,
        secret: data.secret
    };
    const params = {
        method: "PUT",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function getUserExtApi2(data) {
    const url = `${BASE_PATH}/${API_VERSION}/userApi`;
    const bodyObj = {
        tokenUser: data.tokenUser,
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

//------------------------ EXTERNAL API -----------------------
export async function getUserExtApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/users`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

//-----------------------FIREBASE END-POINTS----------------------------

export async function createUserWithFirebase(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/loginFirebase`;
    const bodyObj = {
        email: data.email,
        password: data.password
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function logInWithFirebase(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/siginFirebase`;
    const bodyObj = {
        email: data.email,
        password: data.password
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function signInWithPhoneNumber(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/signinWithPhoneFirebase`;
    const bodyObj = {
        phone: data.phone,
        recaptchaVerifier: data.recaptchaVerifier
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function firebaseLinkProvider(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/linkFirebase`;
    const bodyObj = {
        phonePwd: data.phonePwd,
        confirmationResult: data.confirmationResult
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function firebaseUnlinkProvider() {
    const url = `${BASE_PATH}/${API_VERSION}/user/unlinkFirebase`;
    
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function firebaseRecaptchaVerifier() {
    const url = `${BASE_PATH}/${API_VERSION}/user/recapchaFirebase`;
    
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function currentUserFirebase() {
    const url = `${BASE_PATH}/${API_VERSION}/user/current`;
    
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function endSessionFirebase() {
    const url = `${BASE_PATH}/${API_VERSION}/user/endSession`;
    
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function resetPwdFirebase(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user/resetPwd/${data}`;
    
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Autorization": "*"
        }
    };
    console.log(url);
    return fetch(url, params)
}