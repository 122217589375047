import React from 'react'
import {Layout} from 'antd'
import {useTranslation} from 'react-i18next';
import loadable from '@loadable/component'
import TableauBoard from '../../../components/Admin/Board/Tableau';
import { useParams } from "react-router-dom"
import jwtDecode from 'jwt-decode';
import {ACCESS_TOKEN} from "../../../utils/constants";
import { getActiveBoardByProject } from '../../../api/controller/board'
import '../../../scss/views/board.scss'

//Load the import dynamically
const DynamicComponent = loadable(props => import(`../../../components/Admin/Board/${props.customSettings}`), {
  cacheKey: props => props.customSettings,
})

const Board = () => {

  const [t] = useTranslation() 
  const {Content} = Layout; 
  const {boardUrl} = useParams()

  //Hooks
  const [customSettings, setCustomSettings] = React.useState('Empty')
  const [boardInfo, setBoardInfo] = React.useState(null);
  const [filters, setFilters] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  
  const getBoardSelected = async(bUrl) =>{

    const userStored = localStorage.getItem(ACCESS_TOKEN);
    const user = await jwtDecode(userStored);

    await getActiveBoardByProject({
      boardUrl: bUrl,
      userId: user.id,
      accessToken: userStored
    }).then((result) => {
      if(result.message){
        setBoardInfo(null)
      } else if(result.is_custom === true){
        setBoardInfo(result)
        setCustomSettings(result.customSettings.component)
        
        // (result.is_custom ? setCustomSettings({is_custom:true,component:result.customSettings.component}) : null)
      } else {
        setBoardInfo(result)
      }
    }).catch(() => {
      setBoardInfo(null)
    }) 
  }

  console.log(boardInfo);

  React.useEffect(() => { 

    try {
      getBoardSelected(boardUrl)
    } catch (error) {
      // processAlert("error", "ERROR:", `Error de servidor`);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[boardUrl])

  React.useEffect(() => { 
    if(!boardInfo) return;

    if(boardInfo.board_title !== 'CIR Wireless'){
      setIsLoading(false);
    }
  },[boardInfo])


  const OnBoardChange = async (func) =>{
    await func(setFilters, setIsLoading);
  }

    return (
      <Layout className="board">
        <Content className="board__content">
 
          {boardInfo !== null ? (
            <>
              {boardInfo.is_custom && customSettings !== 'Empty' ? <DynamicComponent customSettings={customSettings} OnUpdateCallback={OnBoardChange}/> : null}
              { !isLoading &&
                <TableauBoard
                  boardInfo={boardInfo}
                  filters={filters}
                />
              }
            </>
          ): ( <div className="fs-1-25" style={{ backgroundColor: "#fafafa !important" }}>{t("common.loading")}</div> ) }
  
        </Content>
      </Layout>
    )
}

export default Board
