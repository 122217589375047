import React, {useState} from "react"
import { Form, Input, Table, Row, Col, /* notification, */ Spin  } from 'antd';
import { withRouter } from "react-router-dom";
// import jwtDecode from 'jwt-decode';
import { CSVLink } from "react-csv";
import './Customers.scss';
import {useTranslation} from 'react-i18next';
// import {ACCESS_TOKEN} from "../../../utils/constants";

import { /* getCustomersSollatek, */ customersData } from '../../../api/controller/sollatek';

import {
    LoadingOutlined
} from '@ant-design/icons';

const Customers = (prop) => {
    const [t] = useTranslation() 

    const [devicesList, setDevicesList] = useState([]);
    const [filterTable, setFilterTable] = useState(null);
    const [form] = Form.useForm();
    // const [editingKey, setEditingKey] = useState('');
    // const [accessT, setAccessT] = useState('');
    // const [cirWifiT, setCirWifi] = useState('');
    const [loading, setLoading] = useState(false);
    
    const delay = 3500;	
    const antLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    /* let processAlert = (className,type, principalMsg, message, placement = 'topLeft') => {
      notification[type]({
        message: principalMsg,
        description: message,
        placement,
        duration: 4.5,
        className: 'sty-notificationAlert '+className,
      });
    }; */

    const groupBy = (objectArray, property) => {
        return objectArray.reduce(function (total, obj) {
            let key = obj[property];
            if (!total[key]) {
                total[key] = [];
            }
            total[key].push(obj);
            return total;
        }, {});
    }

    const showDevices = React.useCallback(async()=>{
        // const userStored = localStorage.getItem(ACCESS_TOKEN);
        // const user = await jwtDecode(userStored);
        // const data = await getUsersSollatek()
        let customersGroups = groupBy(customersData, 'parentCustomerName');
        setLoading(true)
        setDevicesList(customersGroups["Imbera Cooling"])    
        setLoading(false)
    },[/* page, */])

    const search = React.useCallback(async(value)=>{

      const timeout = setTimeout(() => {
        
        const tempFilterTable = devicesList.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
  
        setFilterTable(tempFilterTable)

      }, delay);
      return () => clearTimeout(timeout);
      
    },[devicesList])

    // Para descargas CSV
    const headers = [
      { label: "Customer Name", key: "name" },
      { label: "Type", key: "typeDisplayName" },
      { label: "Outlet", key: "ownedPoiCount" },
      { label: "Assets", key: "assetCount" },
      { label: "Level", key: "level" },
    //   { label: "VAT", key: "n/a" },
      { label: "Timezone", key: "timeZone" },
      { label: "Path", key: "name" },
      { label: "Street Adress", key: "parentCustomerName" },
      { label: "Billing Street Address", key: "dateOfPurchase" },
      { label: "Webpage", key: "activationDate" },
      { label: "Id", key: "id" },
      { label: "Last Modifier Id", key: "id" },
      { label: "Last Update On", key: "modifiedDateTime" },
      { label: "Last Modifier", key: "modifiedDateTime" },
    //   { label: "Creator Id", key: "modifiedDateTime" },
    //   { label: "Created On", key: "createdDateTime" },
    //   { label: "Creator", key: "creatorFullName" },
    ];
    
    const columns = [
      {
        title: '',
        width: '2%',
      },
      {
        title: '',
        width: '3%',
      },
      {
        title: "Customer Name",
        dataIndex: 'name',
        width: '13%',
      },
      {
        title: "Type",
        dataIndex: 'typeDisplayName',
        width: '10%',
      },
      {
        title: "Assets",
        dataIndex: 'assetCount',
        width: '10%',
      },
      {
        title: "Level",
        dataIndex: 'level',
        width: '10%',
      },
      {
        title: 'VAT',
        width: '10%',
        render: (text, record) => {
            return(
              <label>n/a</label>
            )
        },
      },
      {
        title: "Timezone",
        dataIndex: 'timeZone',
        width: '15%',
      },
      {
        title: 'Path',
        width: '22%',
        render: (text, record) => {
            return(
              <label>{record.typeDisplayName} / {record.name}</label>
            )
        },
      },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });


    React.useEffect(() => { 
        showDevices()

    },[showDevices,filterTable])


    return (
        <div>
          <Row justify="space-between pb-4">
            <Col className="title"><h1>{devicesList.length} Customers</h1></Col>
            <Col  className="title-options mb-2">
                <ul>
                    <li className="pr-3 sty-customers-activate">
                        <label className="cursor">Disable users</label>
                    </li>
                    <li className="pr-3 sty-customers-delete">
                        <label className="cursor">Delete</label>
                    </li> 
                    <li className="pr-3 sty-customers-create-new">
                        <label className="cursor">Create New</label>
                    </li>
                    <li className="pr-3">
                        <CSVLink 
                          data={filterTable == null ? devicesList : filterTable} 
                          headers={headers} 
                          className="bx bx-cloud-download sty-downloadTable"
                          filename={"Solkos-DevicesList.csv"}
                        />
                    </li>
                    <li>
                        <div className="search-box">
                          <i className="bx bx-search sty-customers-search"></i>
                          <Input
                            className="searchInput"
                            placeholder={t("common.search")}
                            onChange={(e) => search(e.target.value)}
                          />
                        </div>
                    </li>
                    
                </ul>
              
              
              
              
            </Col>
            
          </Row>

          <Spin spinning={loading} delay={500} indicator={antLoading}>
          <Form form={form} component={false}>
            <Table
              dataSource={filterTable == null ? devicesList : filterTable}
              columns={mergedColumns }
              rowClassName="editable-rows"
              pagination={{
                position: ['topRight'],
                pageSize: 10
              }}
              
              size="small" //Quizas comentar
              onRow={(record, rowIndex) => {
                console.log(record);
                return {
                  // onClick: event => {}, // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {return false}, // right button click row
                  onMouseEnter: event => { event.currentTarget.classList.add("active") }, // mouse enter row
                  onMouseLeave: event => { event.currentTarget.classList.remove("active") }, // mouse leave row
                };
              }}
            />
          </Form>
          </Spin>
          
          
        </div>
    )
}

export default withRouter(Customers)