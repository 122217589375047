import {BASE_PATH, API_VERSION} from '../config/config';

export async function getAllProductsByUserApi(data/* , limit, page */) {
    const url = `${BASE_PATH}/${API_VERSION}/get-products/${data.userId}`; //?limit=${limit}&page=${page}
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function deviceUpdateApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/update-product`;
    const bodyObj = {
        userId: data.userId,
        productId: data.productId,
        row: data.row
    };
    const params = {
        method: "PUT",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function updateStatusDeviceApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/update-product/status`;
    const bodyObj = {
        open: data.open,
        userId: data.userId,
        productId: data.productId,
    };
    const params = {
        method: "PUT",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}


export async function deleteDeviceApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/delete-product/${data.deviceId}`;
    const bodyObj = {
        userId: data.userId,
    };
    const params = {
        method: "DELETE",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}


export async function getAllDevicesExtApi2(data) {
    const url = `${BASE_PATH}/${API_VERSION}/devicesApi`;
    const bodyObj = {
        tokenUser: data.tokenUser,
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function commandDeviceExtApi2(data) {
    const url = `${BASE_PATH}/${API_VERSION}/devicesApi/${data.deviceId}/command`;
    const bodyObj = {
        tokenUser: data.tokenUser,
        command: data.command
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function getCommandsDeviceExtApi2(data) {
    const url = `${BASE_PATH}/${API_VERSION}/devicesApi/${data.deviceId}/get-commands`;
    const bodyObj = {
        tokenUser: data.tokenUser,
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function deleteDeviceExtApi2(data) {
    const url = `${BASE_PATH}/${API_VERSION}/devicesApi/${data.deviceId}/delete`;
    const bodyObj = {
        tokenUser: data.tokenUser,
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}









//------------------------ EXTERNAL API -----------------------
export async function getAllDevicesExtApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/devices`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,"Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function getCommandsDeviceExtApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/devices/${data.deviceId}/commands`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,"Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

//to Cir Wireless board
export async function commandDeviceSN_ExtApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/devices/${data.deviceId}/commands/by_serial_number`;
    const bodyObj = {
        name: data.name, //Lock - Unlock
        package: data.package,
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,
            "Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function commandDeviceExtApi(data) {
    const url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/devices/${data.deviceId}/commands`;
    const bodyObj = {
        name: data.name, //Lock - Unlock
        package: data.package,
        device_id: data.deviceId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,"Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}


export async function deleteDeviceExtApi(data) {
    //Delete /devices/{device_id} :)
    //delete device on product table 

    let url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/devices/${data.id}`;
    let params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,
            "Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)

    if(response.status){
        url = `${BASE_PATH}/${API_VERSION}/delete-product/${data.deviceId}`;
        const bodyObj = {
            userId: data.userId,
        };
        params = {
            method: "DELETE",
            body: JSON.stringify(bodyObj),
            headers: {
                "Content-Type": "application/json",
                "Autorization": data.accessToken
            }
        };
        // console.log(data,url);
        const res = await fetch(url, params)
        return await res.json();
    }else{
        const errorRes = {code:500,message:"Error", response: response.json()}
        return await errorRes.json();
    }

    
}

export async function addDeviceExtApi(data) {
    //PUT /devices - data: serial number
    //Get customer
    //put /customers/{customer_id}/users/{user_id}
    //validated device on product table
    

    let url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/devices`;
    const bodyObj = {
        serial_number: data.deviceId, 
    };
    let params = {
        method: "PUT",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.tokenUser,
            "Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
            // "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)

    // if(response.status){
    //     url = `https://cir-wifi-interface-b7agk5thba-uc.a.run.app/customers/Imber@2021!/users/${data.userId}`;
    //     params = {
    //         method: "PUT",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer " + data.tokenUserR,
    //             "Access-Control-Allow-Headers" : "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
    //             "Access-Control-Allow-Origin": "*",
    //             "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT"
    //             // "Autorization": data.accessToken
    //         }
    //     };
    //     // console.log(data,url);
    //     await fetch(url, params)
    //     return response;
    // }else{
    return response;
    // }
}


export async function checkProductsApi(data) {

    const url = `${BASE_PATH}/${API_VERSION}/check-products`;
    const bodyObj = {
        serie: data.serie,
        userId: data.userId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}

export async function checkProductsErp(data) {
    // data.userId
    const url = `https://us-central1-imberalink-238816.cloudfunctions.net/erp-serie-validator`;
    const bodyObj = {
        "serie": data.serie
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        },
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    console.log(response);
    return response.text();

}

export async function registerProducts(data) {

    const url = `${BASE_PATH}/${API_VERSION}/register-products`;
    const bodyObj = {
        serie: data.serie,
        userId: data.userId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}