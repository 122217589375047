import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from 'react-i18next';
import enTranslation from './en.json';
import esTranslation from './es.json';

const resources ={
    en: {
        translation: enTranslation,
    },
    es: {
        translation: esTranslation,
    }
};

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        //lng: 'es',
        fallbackLng: "en", // use en if detected lng is not available
        keySeparator: false,
        interpolation: {
            escapeValue:false,
        },
    });

export default i18n;