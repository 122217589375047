import {BASE_PATH, API_VERSION} from '../config/config';

export async function getAllActive(data,limit, page) {
    const url = `${BASE_PATH}/${API_VERSION}/board-list?limit=${limit}&page=${page}`;
    const params = {
        method: "GET",
        headers: {
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function getActiveBoardByProject(data) {
    const url = `${BASE_PATH}/${API_VERSION}/board/${data.boardUrl}`;
    const params = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    }; 
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function getActiveByRoles(data/* , limit, page */) {
    const url = `${BASE_PATH}/${API_VERSION}/board-list/user`; //?limit=${limit}&page=${page}
    const bodyObj = {
        userId: data.userId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

