import React from "react"
import { withRouter, Redirect } from "react-router-dom"
import "../../scss/views/login.scss"
import {Layout, Input, Button, Row, Col, Alert, notification } from 'antd'
import { auth, firebaseApp } from "../../firebase"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import { useCountUp } from "react-countup"
import {useTranslation} from 'react-i18next'
import {getAccessTokenApi} from '../../api/auth'
import jwtDecode from 'jwt-decode';

//server methods
import {ACCESS_TOKEN,REFRESH_TOKEN,CIRWIFI_TOKEN,langSolkos,landingUrl} from "../../utils/constants";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { signinApi,signinCirWifiApi, loginApi,loginCirWifiApi,createUserWithFirebase,logInWithFirebase,firebaseUnlinkProvider,firebaseLinkProvider } from '../../api/controller/user'
import { findUserIdByEmail } from '../../api/controller/user_email'
import { findUserIdByPhone, updatePhoneStatus,findPhoneByUserId } from '../../api/controller/user_phone'

//img resources
import brandicon from "../../assets/img/ICONO.png"; 
import brandname from "../../assets/img/SOLKOS.png"; 

//files
import serviceConditions from '../../assets/documents/Condiciones-Solkos.pdf';


const Login = (props) => {

  //Hooks
  const {Content} = Layout; 
  const [t,i18n] = useTranslation();

  /**
  *  secondPassword: confirm pwd for web login
  *  phonePwd: sms code
  *  remotePhoneStat: (firebase status - phone verified) 0 -> link phone to account, 2 -> signin with phone
  *  formStat: 0 -> userexist , 1 -> signin, 2 -> login , 3 -> captchaCode , 4 -> validatesms, 5 -> panel for email 6 -> call to solkos
  *  countResend: check the resend attempts
  *  countSubmit: check the number of submit attempts
  */
  const [input, setInput] = React.useState({id: "", email: "", phone: "", phoneStatus: "", firstPassword:"", secondPassword: "", phonePwd: "" , privacyPolicy: false})
  const [remoteData, setRemoteData] = React.useState({remotePhone: "", remotePhoneStat: null})
  const [temporaryCounter, setTemporaryCounter] = React.useState({formStat: 0, loading: false, isFirstTime: true, counterEnable: false, countResend: 0, countSubmit: 0})
  const [counterEnable,setCounterEnable] = React.useState(false); //Determines if the counter has finished or is enabled
  const [isFirstTime,setIsFirstTime] = React.useState(true);//check the first completed step
  const [jwtToken, setJwtToken] = React.useState({accessToken: "", refreshToken: ""})


  //Variables
  const phoneno = /^\+?(\d{1,4})\)?[(]?(\d{3})[)]?(\d{3})[-]?(\d{4})$/; //regular expression for validate phone number
  let sParameter = "phone"; // allows phone login in email input field
  
  //Methods
  //Used to validate the rules of a valid password
  const [
    validLength,hasNumber,
    upperCase,lowerCase,
    match,specialChar,
    securePasswordLevel, //Returns the number of validations that had success currently
    securePasswordClass //Returns a class according the number of validations that had success
  ] = usePasswordValidation({
    firstPassword: input.firstPassword,
    secondPassword: input.secondPassword,
    requiredLength: 8, // Default Value: 8, you can remove this line
    // numberValidation: false //You can specify if you want to ignore some validator such as numberValidation, lowerCase, etc.
  });

  /**
   * Here you can update the value of the object on "input hook"
   * Input: 
   *  Example: 
   *    e.target.name -> email
   *    e.target.value -> example@example.com
   * Output:
   *  input hook update
   */
  const changeInputForm = e => {
    if(e.target.name === "privacyPolicy"){
      setInput(next => {
        return {
          ...next,
          [e.target.name]: e.target.checked
        }
      });
    }else{
      setInput(next => {
        return {
          ...next,
          [e.target.name]: e.target.value
        }
      });
    }
  };
  

  //FIX: countUp duration
  const easingFn = (a, b, c, d) => {
    return c*a/d + b;
  };

  //Action when the counter reaches 0 while waiting for the sms code
  const onEndCountUp = () => {
    setCounterEnable(false)
    setIsFirstTime(false)
    if(temporaryCounter.countResend>=2){
      checkCoutUp("resend")
    }
  }

  //Counter used to verify sms
  const { countUp, start, reset } = useCountUp({
    start: 60,
    end: 0,
    delay: 1000,
    duration: 60,
    easingFn: easingFn,
    onReset: () => setCounterEnable(false),
    onStart: () => setCounterEnable(true),
    onEnd: () => onEndCountUp(),
  });

  //verify what we do according the status of the counter "CountUp"
  const checkCoutUp = (type) => {
    
    switch (type) {
      //Next step -> Contact solkos / session attempt
      case "submit":
        if((temporaryCounter.countResend >= 2 && !counterEnable) || temporaryCounter.countSubmit > 6){
          setTemporaryCounter(next => {return {...next,"formStat": 5}});
        }else if(temporaryCounter.countSubmit<=6){
          setTemporaryCounter(next => {return {...next,"countSubmit": next.countSubmit+1}});
          console.log(temporaryCounter.countSubmit);
        }
        break;
      //Sms attempt count / Contact solkos
      case "resend":
        if(temporaryCounter.countResend<2){
          setTemporaryCounter(next => {return {...next,"countResend": next.countResend+1}});
          setInput(next => {return {...next,"phonePwd": ""}});
          start()
        }else{
          setCounterEnable(false);
          setTemporaryCounter(next => {return {...next,"formStat": 5}});
        }
        break;
      //Forward SMS
      case "sms":
        setTemporaryCounter(next => {return {...next,"formStat": 4}});
        resendSMS(true)
        break;
      //Contact solkos  
      default:
        setTemporaryCounter(next => {return {...next,"formStat": 5}});
        break;
    }
  }

  //Reset hooks and variables
  const formatReset = () => {
    setTemporaryCounter(next => {return {...next,"formStat": 0}});
    setInput(next => {return {...next,"email": ""}});
    setInput(next => {return {...next,"firstPassword": ""}});
    setInput(next => {return {...next,"secondPassword": ""}});
    setInput(next => {return {...next,"phone": ""}});
    setInput(next => {return {...next,"phonePwd": ""}});
    setRemoteData(next => {return {...next,"remotePhoneStat": null}});
    setRemoteData(next => {return {...next,"remotePhone": ""}});
    setIsFirstTime(true);
    setTemporaryCounter(next => {return {...next,"countResend": ""}});
    setTemporaryCounter(next => {return {...next,"countSubmit": ""}});
  }


  //Alert config
  let processAlert = (className,type, principalMsg, message, placement = 'topLeft', timeLapse = 4.5) => {
    notification[type]({
      message: principalMsg,
      description: message,
      placement,
      duration: timeLapse,
      className: 'sty-notificationAlert '+className,
    });
  };

  //error handler
  const errorControl = (error) => {
    switch (error.code) {
      case "auth/email-already-exists":
        case "auth/email-already-in-use":
        processAlert("","error", "Error:", t("login.alert.email-already-in-use"),'topLeft',4.5); 
        break;
      case "auth/invalid-email":
        processAlert("","error", "Error:", t("login.alert.invalidEmail"),'topLeft',4.5); 
        break;
      case "auth/user-not-found":
        processAlert("","error", "Error:", t("login.alert.userNotFound"),'topLeft',4.5); 
        break;
      case "auth/wrong-password":
        processAlert("","error", "Error:", t("login.alert.wrongPassword"),'topLeft',4.5); 
        break;
      case "auth/invalid-password":
        processAlert("","error", "Error:", t("login.alert.invalidPassword"),'topLeft',4.5); 
        break;
      case "auth/invalid-verification-code":
        processAlert("","error", "Error:", t("login.alert.invalidVerificationCode"),'topLeft',4.5); 
        break;
      case "auth/phone-number-already-exists":
        processAlert("","error", "Error:", t("login.alert.phone-number-already-exists"),'topLeft',4.5); 
        break;
      case "auth/internal-error":
        processAlert("","error", "Error:", t("login.alert.internal-error"),'topLeft',4.5); 
        break;
      default:
        processAlert("","error", "Error:", t("login.alert.default"),'topLeft',4.5); 
        break;
    }
    setTemporaryCounter(next => {return {...next,"loading": false}});
    console.log(error);
    console.log(error.message);
  }

   // > Called by form-submit
   const processData = (e) => {
    e.preventDefault();
    
    //inputs validations
    if (!input.email.trim() && temporaryCounter.formStat <= 2) {processAlert("","error", "Error:", t("login.validation.email"),'topLeft',4.5);return; }
    if (!input.firstPassword.trim() && temporaryCounter.formStat > 0 && temporaryCounter.formStat <= 2) {processAlert("","error", "Error:", t("login.validation.password"),'topLeft',4.5);return;}
    if (!validLength && temporaryCounter.formStat === 1) {processAlert("","error", "Error:", t("login.validation.password.invalid"),'topLeft',4.5);return; }
    if (!match && temporaryCounter.formStat === 1) { processAlert("","error", "Error:", t("login.validation.password.wrong"),'topLeft',4.5);return;}
    if (!hasNumber && temporaryCounter.formStat === 1) { processAlert("","error", "Error:", t("login.validation.password.number"),'topLeft',4.5);return;}
    if (!upperCase && temporaryCounter.formStat === 1) { processAlert("","error", "Error:", t("login.validation.password.uppercase"),'topLeft',4.5);return;}
    if (!lowerCase && temporaryCounter.formStat === 1) { processAlert("","error", "Error:", t("login.validation.password.lowercase"),'topLeft',4.5);return;}
    if (!specialChar && temporaryCounter.formStat === 1) { processAlert("","error", "Error:", t("login.validation.password.specialchar"),'topLeft',4.5);return;}
    if (!input.phone.trim() &&(temporaryCounter.formStat === 3 || temporaryCounter.formStat === 1) &&input.phone.trim().length < 10) {processAlert("","error", "Error:", t("login.validation.phone.invalid"),'topLeft',4.5);return; }
    if (!input.phonePwd.trim() && temporaryCounter.formStat === 4) {processAlert("","error", "Error:", t("login.validation.phone.code"),'topLeft',4.5);return;}

    setTemporaryCounter(next => {return {...next,"loading": true}});

    switch (temporaryCounter.formStat) {
      case 0:
        userExist(); //Check if the user exist
        break;
      case 1:
        userSignin(); //create user
        break;    
      default:
        userLogin(); // enter account
        break;
    } 
  };

  //Check if the user exist: yes -> to login, no -> to signin
  const userExist = async () => {
    reset()
    if (input.email.match(phoneno)) {
      sParameter = "phone";
      setInput(next => {return {...next,"phone": next.email}});
      await findUserIdByPhone(input.email)
      .then((data) => {
        console.log(data);
        if(data.message){
          setTemporaryCounter(next => {return {...next,"loading": false}});
          processAlert("","error", "Error:", data.message,'topLeft',4.5);
        }else{
          captchaCode();
        }
      })
      .catch((error) => {
        console.log(error);
        setTemporaryCounter(next => {return {...next,"loading": false}});
        processAlert("","error", "Error:", t("login.wrong connection"),'topLeft',4.5);
      })
      
    } else {
      sParameter = "email";
      await findUserIdByEmail(input.email)
      .then((data) => {
        if(data){
          setTemporaryCounter(next => {return {...next,"formStat": 2}});
          setTemporaryCounter(next => {return {...next,"loading": false}});
        }else{
          processAlert("","warning", "", t("login.alert.userNotFound"),'topLeft',4.5);
          setTemporaryCounter(next => {return {...next,"formStat": 1}});
          setTemporaryCounter(next => {return {...next,"loading": false}});
        }
      })
      .catch((error) => {
        console.log(error);
        setTemporaryCounter(next => {return {...next,"loading": false}});
        processAlert("","error", "", t("login.wrong connection"),'topLeft',4.5);
      })
    }
  };

  //Create new user - Sign In
  const userSignin = React.useCallback(async () => {
    try {
      const res = await createUserWithFirebase({email:input.email,password:input.firstPassword});
      console.log(res.user.uid);
      if(res.code){
        errorControl(res)
        return;
      }
      //creamos un dato en la coleccion users, con doc podemos hacer un insert con un id custom
      if(res.user.uid){
        await signinApi({
          id: res.user.uid,
          email:input.email,
          phone:input.phone,
          phoneStatus: 0,
          password: input.firstPassword,
          repeatPassword: input.secondPassword,
          privacyPolicy: input.privacyPolicy
        });
  
        await signinCirWifiApi({
          email:input.email,
          password: res.user.uid,
        });

        processAlert("","success", "", t("login.alert.create.user.success"),'topLeft',4.5);
        setInput(next => {return {...next,"secondPassword": ""}});
        setInput(next => {return {...next,"phonePwd": ""}});
        setTemporaryCounter(next => {return {...next,"formStat": 2}});
      }else{
        processAlert("","error", "", 'No se pudo crear el usuario','topLeft',4.5);
      }
      
    } catch (error) {
      errorControl(error)
    }
    setTemporaryCounter(next => {return {...next,"loading": false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const userLogin = () => {
    switch (temporaryCounter.formStat) {
      case 2:
        loginWithEmail();
        break;
      case 3:
        if (window.recaptchaVerifier) {
          resendSMS(true);
        } else {
          captchaCode();
        }
        break;
      case 4:
        verifyCode();
        break;
      default:
        setTemporaryCounter(next => {return {...next,"loading": false}});
        formatReset()
        break;
    }
  };

  //Login
  const loginWithEmail = React.useCallback(async () => {
    try {
        const fUser = await logInWithFirebase({email:input.email, password:input.firstPassword});
        if(fUser.code){
          errorControl(fUser)
          return;
        }
      try {

        if(securePasswordLevel < 5 && temporaryCounter.formStat === 2){ 
          processAlert("","warning", "Warning:", 'Favor de actualizar su contraseña: Recuerda incluir al menos 8 caracteres, mayusculas, minusculas, numeros y caracteres especiales','topLeft',15);
          
          setTemporaryCounter({...temporaryCounter,"loading": false});
          return;
          
        }

        const result = await loginApi({input,userId:fUser.user.uid});
        const cirwifiToken = await loginCirWifiApi({email:input.email, password:fUser.user.uid})
        // const sollatekToken = await getTokenSollatek();
        const {accessToken,refreshToken} = result;
        setJwtToken({...jwtToken,accessToken,refreshToken});
        
        const user = await jwtDecode(result.accessToken);
        localStorage.setItem(CIRWIFI_TOKEN,cirwifiToken.substring(1,cirwifiToken.length - 1))
        // console.log(result);
        //verificamos si sucede algun error al buscar un usuario 
        if(result.message){
          
          // no existe la información del usuario
          processAlert("","error", "", result.message,'topLeft',4.5);
          console.log("User document does not exist!");
        }else{

          const data = await findPhoneByUserId(user.id);
          setInput(next => {return {...next,"id": user.id}});
          switch (data.phone_status) {
            //RemotePhoneStatus == 0 -> the phone has not been verified
            case 0:
              setInput(next => {return {...next,"phone": next.phone}});
              setRemoteData(next => {return {...next,"remotePhoneStat": data.phone_status}});
              setRemoteData(next => {return {...next,"remotePhone": data.phone}});
              
              setTemporaryCounter(next => {return {...next,"formStat": 3}});
              console.log(remoteData.remotePhone)
              break;
            //RemotePhoneStatus == 1 -> phone already linked with the account, only necessary sign in with phone like 2-step verification
            case 1:
              setInput(next => {return {...next,"phone": data.phone}});
              setRemoteData(next => {return {...next,"remotePhone": data.phone}});
              setRemoteData(next => {return {...next,"remotePhoneStat": 0}});
              try {
                await firebaseUnlinkProvider()
              } catch (error) {
                console.log(error);
              }

              setTemporaryCounter(next => {return {...next,"formStat": 3}});
              break;
            //RemotePhoneStatus == 2 -> 2-step verification not necessary
            default:
              setInput(next => {return {...next,"email": ""}});
              setInput(next => {return {...next,"firstPassword": ""}});

              localStorage.setItem(ACCESS_TOKEN,accessToken)
              localStorage.setItem(REFRESH_TOKEN,refreshToken)

              processAlert("","success", "", 'Login correcto','topLeft',4.5);
              props.history.push("/dashboard");
              window.location.reload();
              break;
          }

        }
      
      } catch (error) {
        console.log(error);
        //error al intentar el documento del usuario
        console.log("Error getting the document:", error);
      }
    } catch (error) {
      errorControl(error)
    }
    setTemporaryCounter(next => {return {...next,"loading": false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, securePasswordLevel, props.history]);  

  const captchaCode = async () => {
    reset();
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      "captcha",{size: "invisible",}
    );
    //Reenderizamos el google captcha
    try {
      const widgetId = await window.recaptchaVerifier.render();
      window.recaptchaWidgetId = widgetId;
      sendSMS();
    } catch (error) {
      let codeError = t("login.alert.phone.sent-code-error")
      let codeRes = codeError.replace("%phoneNum%",input.phone)
      processAlert("","error", "Error:", codeRes,'topLeft',4.5);
    }
    setTemporaryCounter(next => {return {...next,"loading": true}});
  };

  const resendSMS = async (send) => {
    
    const widgetId = await window.recaptchaVerifier.render();
    window.recaptchaVerifier.reset(widgetId);
    if (send === true) {
      sendSMS();
      setTemporaryCounter(next => {return {...next,"loading": true}});
      checkCoutUp("resend")
    }
  };

  const sendSMS = React.useCallback(async () => {
    try {
      const res = await auth.signInWithPhoneNumber(
        input.phone ? input.phone : input.email,
        window.recaptchaVerifier
      );
      
      window.confirmationResult = res;

      setTemporaryCounter({...temporaryCounter,"formStat": 4});
      start();
    } catch (error) {
      console.log(error);
      
      const widgetId = await window.recaptchaVerifier.render();
      window.recaptchaVerifier.reset(widgetId);
      captchaCode()
    }
    setTemporaryCounter(next => {return {...next,"loading": false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const verifyCode = React.useCallback(async () => {
    if(temporaryCounter.countSubmit <= 6 && counterEnable === true){

      //TODO: Update phone: remotePhone!==phone&&remotePhoneStat===1 -> desvincular telefono y realizar link (remotePhoneStat=0)
        try {

            switch (remoteData.remotePhoneStat) { 
              case 0:
              case 1:
                try {
                  await firebaseLinkProvider({confirmationResult:window.confirmationResult.verificationId,phonePwd:input.phonePwd})
                  await updatePhoneStatus({
                    userId: input.id ? input.id : auth.currentUser.uid,
                    phoneStatus: 2,
                    phone: input.phone ? input.phone : input.email,
                  })  
                  // console.log(auth.currentUser)
                  let phoneLinked = t("login.alert.phone.link-success")
                  let phoneLinkedRes = phoneLinked.replace("%phoneNum%",input.phone)

                  
                  localStorage.setItem(ACCESS_TOKEN,jwtToken.accessToken)
                  localStorage.setItem(REFRESH_TOKEN,jwtToken.refreshToken)
                  
                  formatReset()
                  processAlert("","success", "", phoneLinkedRes,'topLeft',4.5);
                  props.history.push("/dashboard");
                  window.location.reload()

                } catch (error) {
                  //phone number already linked and errors
                  console.log(error);
                  errorControl(error)
                  setInput(next => {return {...next,"phonePwd": ""}});
                }
                break;
              default:
                await window.confirmationResult.confirm(input.phonePwd);
                let phoneDefault = t("login.alert.phone.success")
                let phoneDefaultRes = phoneDefault.replace("%phoneNum%",input.phone)
                
                formatReset()
                processAlert("","success", "", phoneDefaultRes,'topLeft',4.5);
                

                localStorage.setItem(ACCESS_TOKEN,jwtToken.accessToken)
                localStorage.setItem(REFRESH_TOKEN,jwtToken.refreshToken)
                props.history.push("/dashboard");
                window.location.reload()
                break;
            }
          
        } catch (error) {
            errorControl(error)  
        }
    }
    checkCoutUp("submit")
    setTemporaryCounter(next => {return {...next,"loading": false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, remoteData, props.history,temporaryCounter]);

  if(getAccessTokenApi()){
    return <Redirect to="/dashboard"/>
  }
  
  return (
    <Layout className="login">
      <Content className="login__component">
        
        <div className="login__content">
          <div className="login__content-logo cursor">
            <ul className="login__content-logo-sty">
              <li>
                <a href={landingUrl}>
                  <img src={brandicon} alt="Home" className="img-fluid" />
                  <img src={brandname} alt="Home" className="img-fluid sty-brandname" />
                </a>
              </li>
              <li className="drop-down lang cursor">
                <a href="/login" aria-hidden="true"><i className="bx bx-world worldIcon"></i><span className="d-none">.</span></a>
                <ul>
                  {
                    langSolkos.map(i =>(
                      <li>
                        <span className="link" onClick={() => i18n.changeLanguage(i.short)}>
                          {i.full}
                        </span>
                      </li>
                    ))
                  }
                </ul>

                
              </li>
              
            </ul>
            
          </div>
    
          <section className="login__content-card">
            <div className="login__content-card-title">
              <h5>
                {temporaryCounter.formStat === 0 || temporaryCounter.formStat === 2
                  ? t("login.login")
                  : temporaryCounter.formStat === 1
                  ? t("login.signup")
                  : temporaryCounter.formStat === 3
                  ? t("login.confirm.phone")
                  : t("login.validate.phone")
                }
              </h5>
            </div>
            <div>
              {alert.error && <Alert message={`${alert.error}`} type="error" className="mb-2" showIcon />}
              <form onSubmit={processData} className="loginForm">
                <Input 
                    className={`sty-inputForm ${temporaryCounter.formStat > 2 ? "d-none" : ""}`}
                    type={sParameter}
                    placeholder={temporaryCounter.formStat === 0 ? t("login.input.email.step0") : t("login.input.email.title")}
                    onChange={changeInputForm}
                    name="email"
                    value={input.email}
                />
                <Input.Password
                  className={`sty-inputPassForm ${temporaryCounter.formStat === 0 || temporaryCounter.formStat > 2 ? "d-none" : ""}`}
                  type="password"
                  placeholder={t("login.input.password.title")}
                  onChange={changeInputForm}
                  value={input.firstPassword}
                  name="firstPassword"
                />

                <div class={`input-password__marker ${temporaryCounter.formStat === 1 ? "" : "d-none"}`}>
                  <div class={`${securePasswordClass}`}></div>
                  <div class={`${securePasswordLevel > 1 ? securePasswordClass : "none"}`}></div>
                  <div class={`${securePasswordLevel > 2 ? securePasswordClass : "none"}`}></div>
                  <div class={`${securePasswordLevel > 4 ? securePasswordClass : "none"}`}></div>
                </div>
                
                <Input.Password
                  className={`sty-inputPassForm ${temporaryCounter.formStat === 1 ? "" : "d-none"}`}
                  type="password"
                  placeholder={t("login.input.password.repeat")}
                  onChange={changeInputForm}
                  value={input.secondPassword}
                  name="secondPassword"
                />

                <div
                  className={`mb-2 ${temporaryCounter.formStat === 1 || temporaryCounter.formStat === 3 || temporaryCounter.formStat ===4 ? "" : "d-none"}`}
                >
                  <PhoneInput
                    inputClass={`w-100 sty-inputForm sty-invalidPhone ${ (temporaryCounter.formStat >= 4 ? "disabled" : "") }`}
                    disabled={temporaryCounter.formStat === 4  ? true : false}
                    enableSearch={true}
                    autoFormat={true}
                    searchStyle={{ width: "93%","borderRadius": "10px" }}
                    country={"mx"}
                    value={input.phone}
                    searchNotFound={t("login.input.phone.withoutData")}
                    inputProps={{ id: "phoneInput" }}
                    onChange={
                      value => setInput({...input,"phone": '+'+value})
                    }
                    isValid={(value, country) => {
                      if (value.match(phoneno)) {
                        const phone = document.getElementById("phoneInput").value.replace(/-| |\(|\)/g, "").toString();
                        const planePhone = phone.replace("+" + country.countryCode,"" );
                        if (planePhone.length !== 10) {
                          return t("login.input.phone.invalid");
                        }
                      } else {
                        return t("login.input.phone.invalid");
                      }
                    }}
                  />
                </div>
                <div className={`mb-2 ${temporaryCounter.formStat < 4 ? "d-none" : ""}`}>
                  <Input
                    className={`sty-invalidPhone ${temporaryCounter.formStat !== 4 ? "d-none" : ""}`}
                    type="text"
                    placeholder={t("login.input.phone.validationConfirm")}
                    onChange={changeInputForm}
                    value={input.phonePwd}
                    name="phonePwd"
                  />
                  <div className={`mt-2 ${temporaryCounter.formStat <= 4 ? "countCaptcha" : "panelSolkos"}  ${temporaryCounter.formStat < 4 ? "d-none" : ""}`}>
                    {
                      temporaryCounter.formStat === 4
                        ?(<span>{countUp}</span>)
                        :(
                          <div>
                            <p>{t("login.contact.solkos")}</p>
                            <p>{t("login.contact.email")}</p>
                            <p>{t("login.contact.phone")}</p>
                          </div>
                        )
                    }
                  </div>
                  
                  {isFirstTime === false && temporaryCounter.countResend < 2 && temporaryCounter.countSubmit <= 5 ?(
                    <div className="resendCode">
                    <ul>
                      <li>
                        <span
                          className="sty-resendCode cursor"
                          onClick={() => checkCoutUp("sms")}
                        >
                          Forward SMS
                        </span>
                      </li>
                    </ul>
                    </div>
  
                  ) : null}
  
                </div>
                <div id="captcha"></div>
                <Button 
                  block={true}
                  className={`loginSubmit ${temporaryCounter.loading ? "disabled" : ""}`}
                  type="primary" htmlType="submit"
                >
                  {temporaryCounter.loading ? (
                    <i className="bx bx-loader-alt loadElement"></i>
                  ) : (
                    <span className="centerSpan">
                      {temporaryCounter.formStat === 0 || temporaryCounter.formStat === 2
                        ? t("common.continue")
                        : temporaryCounter.formStat === 1
                        ? t("common.regist")
                        : temporaryCounter.formStat === 3
                        ? t("common.send")
                        : temporaryCounter.formStat === 4
                        ? t("common.validate")
                        : t("common.return")
                      }
                    </span>
                  )}
                </Button>
              </form>
            </div>
            <div className="loginFooter">
              <ul>
                {temporaryCounter.formStat === 2 ? (
                  <li className="pr-3">
                    <a href="/recovery">
                      <span>{t("login.passwordRecovery")}</span>
                    </a>
                  </li>
                ) : null}
                <li>
                  <span
                    className={`toggleRegistry cursor ${temporaryCounter.formStat > 2 ? "d-none" : ""}`}
                    onClick={() => setTemporaryCounter({...temporaryCounter,"formStat": temporaryCounter.formStat === 0 ? 1 : temporaryCounter.formStat === 2 ? 1 : 2})}>{temporaryCounter.formStat === 1? t("login.signIn"): t("login.signUp")}
                  </span>
                </li>
              </ul>
            </div>
            <Row justify="space-between  pb-3">
              <Col span={11} className="text-left"><a href={serviceConditions} target ="_blank" rel="noopener noreferrer">{t("footer.user.links.terms.service")}</a></Col>
              <Col span={11} className="text-right"><a href="https://www.solkos.com.mx/aviso-privacidad/aviso-privacidad-solkos.html" target ="_blank" rel="noopener noreferrer">{t("footer.privacy.policy")}</a></Col>
            </Row> 
          </section>
        </div>
      </Content>
    </Layout>
  );
};

export default withRouter(Login);
