import React, {useState} from "react"
import {  Form, Input, Table, Row, Col, /* notification, */ Spin  } from 'antd';
import { withRouter } from "react-router-dom";
// import jwtDecode from 'jwt-decode';
import { CSVLink } from "react-csv";
import './Outlets.scss';
import {useTranslation} from 'react-i18next';
// import {ACCESS_TOKEN} from "../../../utils/constants";

import { /* getOutletsSollatek, */ pointOfInterests } from '../../../api/controller/sollatek';

import {
    LoadingOutlined
} from '@ant-design/icons';

const Outlets = (prop) => {
    const [t] = useTranslation() 

    const [devicesList, setDevicesList] = useState([]);
    const [filterTable, setFilterTable] = useState(null);
    const [form] = Form.useForm();
    // const [editingKey, setEditingKey] = useState('');
    // const [accessT, setAccessT] = useState('');
    // const [cirWifiT, setCirWifi] = useState('');
    const [loading, setLoading] = useState(false);
    
    const delay = 3500;	
    const antLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    /* let processAlert = (className,type, principalMsg, message, placement = 'topLeft') => {
      notification[type]({
        message: principalMsg,
        description: message,
        placement,
        duration: 4.5,
        className: 'sty-notificationAlert '+className,
      });
    }; */

    const showDevices = React.useCallback(async()=>{
        setLoading(true)
        // const userStored = localStorage.getItem(ACCESS_TOKEN);
        // const user = await jwtDecode(userStored);
        // const data = await getUsersSollatek()
        setDevicesList(pointOfInterests)   
        setLoading(false) 
    },[/* page, */])

    const search = React.useCallback(async(value)=>{

      const timeout = setTimeout(() => {
        
        const tempFilterTable = devicesList.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
  
        setFilterTable(tempFilterTable)

      }, delay);
      return () => clearTimeout(timeout);
      
    },[devicesList])

    // Para descargas CSV
    const headers = [
      { label: "POI ERP", key: "erpCode" },
      { label: "Name", key: "name" },
    //   { label: "Outlet Type", key: "name" },
      { label: "Customer Name", key: "customerName" },
    //   { label: "VAT", key: "n/a" },
      { label: "Assets", key: "assignedAssets" },
      { label: "Groups", key: "poiClusterIds" },
      { label: "Street Adress", key: "street" },
      { label: "Id", key: "id" },
      { label: "Customer Id", key: "customerId" },
      { label: "Last Modifier Id", key: "modifierUserId" },
      { label: "Last Update On", key: "modifiedDateTime" },
      { label: "Last Modifier", key: "modifierFullName" },
      { label: "Creator Id", key: "creatorUserId" },
      { label: "Created On", key: "createdDateTime" },
      { label: "Creator", key: "creatorFullName" },
    ];
    
    const columns = [
      {
        title: '',
        width: '2%',
      },
      {
        title: '',
        width: '3%',
      },
      {
        title: "POI ERP",
        dataIndex: 'erpCode',
        width: '13%',
      },
      {
        title: "Name",
        dataIndex: 'name',
        width: '26%',
      },
      {
        title: "Outlet Type",
        dataIndex: 'typeValue',
        width: '15%',
        render: (text, record) => {
            return(
              <label>Sale</label>
            )
        },
      },
      {
        title: "Customer Name",
        dataIndex: 'customerName',
        width: '16%',
      },
      
      {
        title: 'VAT',
        width: '8%',
        render: (text, record) => {
            return(
              <label>n/a</label>
            )
        },
      },
      {
        title: "Assets",
        dataIndex: 'assignedAssets',
        width: '8%',
      },
      {
        title: 'Groups',
        dataIndex: "poiClusterIds",
        width: '15%',
        render: (text, record) => {
            return(
              <label>None</label>
            )
        },
      },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });


    React.useEffect(() => { 
        showDevices()

    },[showDevices,filterTable])


    return (
        <div>
          <Row justify="space-between pb-4">
            <Col className="title"><h1>{devicesList.length} Outlets</h1></Col>
            <Col  className="title-options mb-2">
                <ul>
                    <li className="pr-3 sty-outlets-activate">
                        <label className="cursor">Assign to Group</label>
                    </li>
                    <li className="pr-3 sty-outlets-delete">
                        <label className="cursor">Delete</label>
                    </li> 
                    <li className="pr-3 sty-outlets-create-new">
                        <label className="cursor">Create New</label>
                    </li>
                    <li className="pr-3">
                        <CSVLink 
                          data={filterTable == null ? devicesList : filterTable} 
                          headers={headers} 
                          className="bx bx-cloud-download sty-downloadTable"
                          filename={"Solkos-DevicesList.csv"}
                        />
                    </li>
                    <li>
                        <div className="search-box">
                          <i className="bx bx-search sty-outlets-search"></i>
                          <Input
                            className="searchInput"
                            placeholder={t("common.search")}
                            onChange={(e) => search(e.target.value)}
                          />
                        </div>
                    </li>
                    
                </ul>
              
              
              
              
            </Col>
            
          </Row>

          <Spin spinning={loading} delay={500} indicator={antLoading}>
          <Form form={form} component={false}>
            <Table
              dataSource={filterTable == null ? devicesList : filterTable}
              columns={mergedColumns }
              rowClassName="editable-rows"
              pagination={{
                position: ['topRight'],
                pageSize: 10
              }}
              
              size="small" //Quizas comentar
              onRow={(record, rowIndex) => {
                console.log(record);
                return {
                  // onClick: event => {}, // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {return false}, // right button click row
                  onMouseEnter: event => { event.currentTarget.classList.add("active") }, // mouse enter row
                  onMouseLeave: event => { event.currentTarget.classList.remove("active") }, // mouse leave row
                };
              }}
            />
          </Form>
          </Spin>
          
          
        </div>
    )
}

export default withRouter(Outlets)