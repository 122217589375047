import React from "react";
import { Redirect, Route, Switch,withRouter, useLocation } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import { Layout, Menu } from "antd";
import jwtDecode from 'jwt-decode';
import "./LayoutAdmin.scss";
import Navbar from '../components/Admin/Nabvar/Nabvar';
import login from '../views/SignIn';
import useAuth from '../hooks/useAuth';
import BreadCrumb from '../components/BreadCrumb';
import {ACCESS_TOKEN} from "../utils/constants";

import { getFeaturesByUserApi } from '../api/controller/features.js';

const LayoutAdmin = (props) => {
  const [t] = useTranslation()
  const { routes } = props;
  const location = useLocation();  
  
  const { Header, Content, Sider/* , Footer */  } = Layout;
  const [menuCollapsed, setMenuCollapsed] = React.useState({collapsed:true});
  const [featuresList, setFeaturesList] = React.useState([]); 

  const {user, isLoading} = useAuth();

  const featuresToUser = async() => {
    const userStored = localStorage.getItem(ACCESS_TOKEN);
    if (!userStored || userStored === "undefined") {
      return;
    }
    const userF = await jwtDecode(userStored);
    const data = await getFeaturesByUserApi({userId:userF.id,accessToken:userStored});

    // Logica de separación de features
    const featuresArr = await data.filter(i => i.isMenu === true )
    setFeaturesList(featuresArr)
    // Array de datos para consumo en consulta
    // const featuresList = featuresArr.map(i => i.id) // Muestra lista de proyectos del usuario sin filtros

  }
  React.useEffect(() => { 
    featuresToUser()
  },[])

  if(!user && !isLoading){
    return (
      <>
      <Route path="/login" component={login} />
      <Redirect to="/login" />
      </>
    )
  }


  if(user && !isLoading){
    return (
      <Layout /* style={{ minHeight: '100vh' }} */>
         <Header><Navbar/></Header>
         <Layout>
          <Sider className="sty-sider" collapsible collapsed={menuCollapsed.collapsed} onCollapse={(collapsed) => setMenuCollapsed({collapsed})} style={{"width":"65px"}}>
            <Menu theme="dark" selectedKeys={[location.pathname.split('/')[1]]} mode="inline" className="sty-menuSidebar">
            {
              featuresList.map( i =>(
              <>
                <Menu.Item key={i.feature_dsc_i18n} icon={<i className={i.icon_class}></i>} onClick={ () => props.history.push(`/${i.feature_dsc_i18n}`) }>
                 {t(i.description_i18n)}
                </Menu.Item>
              </>
              ))
            }
            </Menu>
          </Sider>
        <Layout className="site-layout">
         
          <Content style={{ margin: '0 16px' }}>
            <BreadCrumb/>
            <div className="site-layout-background" style={{ padding: "0px 24px 24px 24px", minHeight: 360 }}>
              <LoadRoutes routes={routes} />
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Solkos ©2021</Footer> */}
        </Layout>
        </Layout>
      </Layout>
    );
  }
  
  return null;
};

const LoadRoutes = ({ routes }) => {
  
  return (
    <Switch>
      {routes.map((route, index) => (
        

        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export default withRouter(LayoutAdmin);
