import React from 'react'
import {useLocation, Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';

const BreadCrumb = () => {
    const location = useLocation();
    const breadCrumbView = () => {
        const {pathname} = location;
        const pathnames = pathname.split("/").filter((item) => item);
        const capitalize = (s) => s.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

    return (
        <div>
            {<i className="bx bxs-home mr-2" style={{float: 'left',color: 'rgba(0, 0, 0, 0.45)', transition: 'color 0.3s', marginTop: '3px' }}></i>}
           <Breadcrumb style={{margin: '1rem -0.15rem'}}>

            {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                const isLast = index === pathnames.length -1;

                return isLast ? (
                    <Breadcrumb.Item>
                    {capitalize(name)}
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item>
                    <Link to={`${routeTo}`}>{capitalize(name)}</Link>
                    </Breadcrumb.Item>
                )
            })}
           </Breadcrumb>
        </div>
    );
    };
    return <>{breadCrumbView()}</>;
};

export default BreadCrumb