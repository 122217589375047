import React, {useState} from "react"
import { Form, Input, Table, Space, Row, Col, /* notification, */ Spin  } from 'antd';
import { withRouter } from "react-router-dom";
// import jwtDecode from 'jwt-decode';
import { CSVLink } from "react-csv";
import './assets.scss';
import {useTranslation} from 'react-i18next';
// import {ACCESS_TOKEN} from "../../../utils/constants";

import { /* getDevicesSollatek, */ assetsData } from '../../../api/controller/sollatek';

import {
    LoadingOutlined
} from '@ant-design/icons';

const Assets = (prop) => {
    const [t] = useTranslation() 

    const [devicesList, setDevicesList] = useState([]);
    const [filterTable, setFilterTable] = useState(null);
    const [form] = Form.useForm();
    // const [editingKey, setEditingKey] = useState('');
    // const [accessT, setAccessT] = useState('');
    // const [sollatekToken, setSollatekToken] = useState('');
    const [loading, setLoading] = useState(false);
    
    const delay = 3500;	
    const antLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    /* let processAlert = (className,type, principalMsg, message, placement = 'topLeft') => {
      notification[type]({
        message: principalMsg,
        description: message,
        placement,
        duration: 4.5,
        className: 'sty-notificationAlert '+className,
      });
    }; */



    const showDevices = React.useCallback(async()=>{
        // const userStored = localStorage.getItem(ACCESS_TOKEN);
        // const user = await jwtDecode(userStored);
        // const data = await getDevicesSollatek(/*sollatekToken , 12, page */)
        setLoading(true)
        setDevicesList(assetsData)  
        setLoading(false)  
    },[/* page, */])

    const search = React.useCallback(async(value)=>{

      const timeout = setTimeout(() => {
        
        const tempFilterTable = devicesList.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
  
        setFilterTable(tempFilterTable)

      }, delay);
      return () => clearTimeout(timeout);
      
    },[devicesList])

    // Para descargas CSV
    const headers = [
      { label: "Asset Serial", key: "serial" },
      { label: "Outlet", key: "pointOfInterestFullAddress" },
      { label: "Vendor", key: "vendorName" },
      { label: "Model", key: "modelName" },
      { label: "Online?", key: "connectivityDisplayName" },
      { label: "Mode", key: "modeDisplayName" },
      { label: "Status", key: "statusDisplayName" },
      { label: "Platform Device Serial #", key: "serial" },
      { label: "Date of 1st Connection", key: "createdDateTime" },
      { label: "Date of Purchase", key: "dateOfPurchase" },
      { label: "Date of Configuration", key: "activationDate" },
      { label: "Asset Owner", key: "ownerCustomerName" },
      { label: "Id", key: "id" },
      { label: "Customer Owner Id", key: "ownerCustomerId" },
      { label: "Last Modifier Id", key: "modifierId" },
      { label: "Last Update On", key: "modifiedDateTime" },
      { label: "Last Modifier", key: "modifierFullName" },
      { label: "Creator Id", key: "creatorId" },
      { label: "Created On", key: "createdDateTime" },
      { label: "Creator", key: "creatorFullName" },
    ];
    
    const columns = [
      {
        title: '',
        width: '2%',
      },
      {
        title: '',
        width: '3%',
      },
      {
        title: "Asset Serial",
        dataIndex: 'serial',
        width: '10%',
      },
      {
        title: "Outlet",
        dataIndex: 'pointOfInterestFullAddress',
        width: '35%',
      },
      {
        title: "Vendor",
        dataIndex: 'vendorName',
        width: '15%',
      },
      {
        title: "Model",
        dataIndex: 'modelName',
        width: '10%',
      },
      {
        title: 'Asset Status Info',
        width: '25%',
        children: [
          {
            title: 'Online?',
            dataIndex: 'connectivityDisplayName',
            key: 'connectivityDisplayName',
            className: 'sty-assets-wifi',
            render: (text, record) => {
              return(
                text === "Offline" ? (
                    <i className="bx bx-wifi-off"></i>
                  ) : (
                    <i className="bx bx-wifi"></i>
                  )
              )
            },
          },
          {
            title: 'Mode',
            dataIndex: 'modeDisplayName',
            key: 'modeDisplayName',
            className: 'sty-assets-mode',
            render: (text, record) => {
                return(
                  text === "Unmonitored" ? (
                        <i className="bx bx-sync"></i>
                    ) : (
                        <label className="sty-text">{text}</label>
                    )
                )
              },
          },
          {
            title: 'Status',
            dataIndex: 'statusDisplayName',
            key: 'statusDisplayName',
            className: 'sty-assets-status',
            render: (text, record) => {
                return(
                  text === "Unpaired" ? (
                      <Space size="middle ">
                          <i className="bx bxs-x-circle mr-0"></i> <label className="sty-status-x">{text}</label>
                      </Space>
                    ) : (
                      <Space size="middle ">
                          <i className="bx bxs-check-circle mr-0"></i> <label className="sty-status-check">{text}</label>
                      </Space>
                    )
                )
              },
          },
        ],
      },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });


    React.useEffect(() => { 
        showDevices()

    },[showDevices,filterTable])


    return (
        <div>
          <Row justify="space-between pb-4">
            <Col className="title"><h1>46 Assets</h1></Col>
            <Col  className="title-options mb-2">
                <ul>
                    <li className="pr-3 sty-assets-activate">
                        <label className="cursor">Activate</label>
                    </li>
                    <li className="pr-3 sty-assets-delete">
                        <label className="cursor">Delete</label>
                    </li> 
                    <li className="pr-3 sty-assets-create-new">
                        <label className="cursor">Create New</label>
                    </li>
                    <li className="pr-3">
                        <CSVLink 
                          data={filterTable == null ? devicesList : filterTable} 
                          headers={headers} 
                          className="bx bx-cloud-download sty-downloadTable"
                          filename={"Solkos-DevicesList.csv"}
                        />
                    </li>
                    <li>
                        <div className="search-box">
                          <i className="bx bx-search sty-assets-search"></i>
                          <Input
                            className="searchInput"
                            placeholder={t("common.search")}
                            onChange={(e) => search(e.target.value)}
                          />
                        </div>
                    </li>
                    
                </ul>
              
              
              
              
            </Col>
            
          </Row>

          <Spin spinning={loading} delay={500} indicator={antLoading}>
          <Form form={form} component={false}>
            <Table
              dataSource={filterTable == null ? devicesList : filterTable}
              columns={mergedColumns }
              rowClassName="editable-rows"
              pagination={{
                position: ['topRight'],
                pageSize: 10
              }}
              
              size="small" //Quizas comentar
              onRow={(record, rowIndex) => {
                console.log(record);
                return {
                  // onClick: event => {}, // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {return false}, // right button click row
                  onMouseEnter: event => { event.currentTarget.classList.add("active") }, // mouse enter row
                  onMouseLeave: event => { event.currentTarget.classList.remove("active") }, // mouse leave row
                };
              }}
            />
          </Form>
          </Spin>
          
          
        </div>
    )
}

export default withRouter(Assets)