import React from 'react'
import {auth} from '../../firebase'
import {withRouter, Redirect} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {getAccessTokenApi} from '../../api/auth'
import {Layout, Input, Button, Row, Col,notification } from 'antd'
import '../../scss/views/resetPwd.scss'

import { findUserIdByEmail } from '../../api/controller/user_email'
import { updatePhoneStatus } from '../../api/controller/user_phone'
import { resetPwdFirebase } from '../../api/controller/user'
import {langSolkos,landingUrl} from "../../utils/constants";

//img resources
import brandicon from "../../assets/img/ICONO.png"; 
import brandname from "../../assets/img/SOLKOS.png"; 

//files
import serviceConditions from '../../assets/documents/Condiciones-Solkos.pdf';
import privacyPolicy from '../../assets/documents/AP_Integral_App_Solkos_Connected_vrf.pdf';

const ResetPdw = (props) => {
     //Hooks
    const {Content} = Layout;
    const [t,i18n] = useTranslation()
    const [email, setEmail] = React.useState('')
    const [loading, setLoading] = React.useState(false);


    let processAlert = (className,type, principalMsg, message, placement = 'topLeft', timeLapse = 4.5) => {
      setLoading(true);
      notification[type]({
        message: principalMsg,
        description: message,
        placement,
        duration: timeLapse,
        className: 'sty-notificationAlert '+className,
      });
      setLoading(false);
      setTimeout(function () {
        props.history.push('/')
      }, timeLapse+2);
    };

    const processData = e => {
        e.preventDefault()

        if(!email.trim()){
            processAlert("","error", "Error:", t("common.input.email.title"),'topLeft',4.5);
            return
        }
        recoveryPwd()
    }

    const recoveryPwd = React.useCallback( async() => {
        try {
            const res = await resetPwdFirebase(email)
            if(res.code){
              processAlert("","error", "Error:", t("resetPwd.email.sending.error"),'topLeft',4.5);
              console.log(res.code)
              return;
            }
            
            const query = await findUserIdByEmail(email)
            console.log(query);
            
            if(query){
              await updatePhoneStatus({
                userId: query.user_id ? query.user_id : auth[0].currentUser.uid,
                phoneStatus: 1
              }) 
            }

            processAlert("","success", "", t("resetPwd.email.sending.success"),'topLeft',4.5);
        } catch (error) {
            processAlert("","error", "Error:", t("resetPwd.email.sending.error"),'topLeft',4.5);
            console.log(error)
        }
    // eslint-disable-next-line
    },[email,props.history])

    if(getAccessTokenApi()){
      return <Redirect to="/dashboard"/>
    }

    return (
        <Layout className="resetPwd">
            <Content className="resetPwd__component">
              <div className="login__content">
                <div className="resetPwd__content-logo cursor">
                  <ul className="resetPwd__content-logo-sty">
                    <li>
                      <a href={landingUrl}>
                        <img src={brandicon} alt="Home" className="img-fluid" />
                        <img src={brandname} alt="Home" className="img-fluid sty-brandname" />
                      </a>
                    </li>
                    <li className="drop-down lang cursor">
                      <a href="/recovery" aria-hidden="true"><i className="bx bx-world worldIcon"></i><span className="d-none">.</span></a>
                      <ul>
                        {
                          langSolkos.map(i =>(
                            <li>
                              <span className="link" onClick={() => i18n.changeLanguage(i.short)}>
                                {i.full}
                              </span>
                            </li>
                          ))
                        }
                      </ul>
                      
                      
                    </li>
                      
                  </ul>
                      
                </div>
                <section className="resetPwd__content-card">
                    <div className="resetPwd__content-card-title">
                        <h5>{t("resetPwd.password.recovery")}</h5>
                    </div>
                    <div>
                        <form onSubmit={processData} className="loginForm">
                            <div className="input-group mb-2">  
                                <Input 
                                    className={`sty-inputForm form-control`}
                                    type="email"
                                    placeholder={t("common.input.email.title")}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <Button 
                              block={true}
                              className={`loginSubmit`}
                              type="primary" htmlType="submit"
                            >{loading ? (
                                <i className="bx bx-loader-alt loadElement"></i>
                              ) : (
                                <span className="centerSpan">
                                    {t("resetPwd.password.title")}
                                </span>
                             )}
                            </Button>
                        </form>
                    </div>
                    <div className="loginFooter">
                      <ul></ul>
                    </div>
                    <Row justify="space-between  pb-1">
                      <Col span={11} className="text-left"><a href={serviceConditions} target ="_blank" rel="noopener noreferrer">{t("footer.user.links.terms.service")}</a></Col>
                      <Col span={11} className="text-right"><a href={privacyPolicy} target ="_blank" rel="noopener noreferrer">{t("footer.privacy.policy")}</a></Col>
                    </Row> 
                </section> 
              </div>
            </Content>
        </Layout>
    )
}

export default withRouter(ResetPdw) 
                    
                   