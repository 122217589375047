// ----------- Google Analytics ------------//
import GA4React, { useGA4React } from "ga-4-react";

const ga4react = new GA4React("G-4NP5HDFNGK"); //QAS
//const ga4react = new GA4React("G-VCDYRFTKTV");//PRD 

function customGA4Init(){ 
  ga4react.initialize();
  //console.log("inicializado");
}

const customGA4 = (props) => { 
    //console.log("reactGA4:",ga4react,"props:",props);

    ga4react.gtag(
        props.event,
        props.pageview,
        props.path
    );   

    ga4react.event( props.event, props.pageview, props.path, false); 
}


export { customGA4Init }
export default customGA4