export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const CIRWIFI_TOKEN = "cirwifiToken";

//Languages
export const langSolkos = [
    {"short":"es","full":"Español"},
    {"short":"en","full":"English"},
]

//URL References

//Landing page
export const landingUrl = "https://solkos.com.mx/";
//Console
export const consoleUrl = "https://console.solkos.com.mx/"; //"http://localhost:3000/";