import React, {useState} from "react"
import {  Form,  Input, Table, Space, Row, Col, /* notification, */ Spin  } from 'antd';
import { withRouter } from "react-router-dom";
// import jwtDecode from 'jwt-decode';
import { CSVLink } from "react-csv";
import './Users.scss';
import {useTranslation} from 'react-i18next';
// import {ACCESS_TOKEN} from "../../../utils/constants";

import { /* getUsersSollatek, */ usersData } from '../../../api/controller/sollatek';

import {
    LoadingOutlined
} from '@ant-design/icons';

const Users = (prop) => {
    const [t] = useTranslation() 

    const [devicesList, setDevicesList] = useState([]);
    const [filterTable, setFilterTable] = useState(null);
    const [form] = Form.useForm();
    // const [editingKey, setEditingKey] = useState('');
    // const [accessT, setAccessT] = useState('');
    // const [cirWifiT, setCirWifi] = useState('');
    const [loading, setLoading] = useState(false);
    
    const delay = 3500;	
    const antLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    /* let processAlert = (className,type, principalMsg, message, placement = 'topLeft') => {
      notification[type]({
        message: principalMsg,
        description: message,
        placement,
        duration: 4.5,
        className: 'sty-notificationAlert '+className,
      });
    }; */



    const showDevices = React.useCallback(async()=>{
        setLoading(true)
        // const userStored = localStorage.getItem(ACCESS_TOKEN);
        // const user = await jwtDecode(userStored);
        // const data = await getUsersSollatek()
        setDevicesList(usersData) 
        setLoading(false)   
    },[/* page, */])

    const search = React.useCallback(async(value)=>{

      const timeout = setTimeout(() => {
        
        const tempFilterTable = devicesList.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
  
        setFilterTable(tempFilterTable)

      }, delay);
      return () => clearTimeout(timeout);
      
    },[devicesList])

    // Para descargas CSV
    const headers = [
      { label: "User Name", key: "fullName" },
      { label: "Status", key: "userStatusValue" },
      { label: "Role", key: "roleName" },
      { label: "Customer Name", key: "customerName" },
      { label: "Outlet Groups", key: "roleCategoryValue" },
      { label: "Last Login", key: "lastLoginDateTime" },
      { label: "Email", key: "email" },
      { label: "Phone Number", key: "phoneNumber" },
      { label: "Id", key: "id" },
      { label: "Customer Id", key: "customerId" },
      { label: "Role Id", key: "roleId" },
      { label: "Last Modifier Id", key: "modifierUserId" },
      { label: "Last Update On", key: "modifiedDateTime" },
      { label: "Last Modifier", key: "modifierFullName" },
      { label: "Creator Id", key: "creatorUserId" },
      { label: "Created On", key: "createdDateTime" },
      { label: "Creator", key: "creatorFullName" },
    ];
    
    const columns = [
      {
        title: '',
        width: '2%',
      },
      {
        title: '',
        width: '3%',
      },
      {
        title: "User Name",
        dataIndex: 'fullName',
        width: '25%',
        render: (text, record) => {
            return(
                <Space size="middle">
                  <label class={`cirle-name ${record.roleCategoryValue === 1 ? "category-1" : "category-0"}`}>
                      <span className="sty-circle-name-fonts">{(record.firstName).split("")[0]} {(record.lastName).split("")[0]}</span>
                  </label>
                  <label>{record.fullName}</label>
                </Space>
            )
          },
      },
      {
        title: "Status",
        dataIndex: 'userStatusValue',
        width: '5%',
        className: "text-center sty-shield",
        render: (text, record) => {
          return(
            text === 1 ? (
                <Space size="middle">
                    <i className="bx bx-check-shield active"></i>
                </Space>
              ) : (
                <Space size="middle">
                    <i className="bx bx-check-shield "></i>
                </Space>
              )
          )
        },
      },
      {
        title: "Role",
        dataIndex: 'roleName',
        width: '10%',
      },
      {
        title: "Customer Name",
        dataIndex: 'customerName',
        width: '17%',
      },
      {
        title: 'Outlet Groups',
        width: '15%',
        dataIndex: 'customerName',
        render: (text, record) => {
            return(
              <label>None</label>
            )
        },
      },
      {
        title: "Last Login",
        dataIndex: 'lastLoginDateTime',
        width: '23%',
      },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });


    React.useEffect(() => { 
        showDevices()

    },[showDevices,filterTable])


    return (
        <div>
          <Row justify="space-between pb-4">
            <Col className="title"><h1>{devicesList.length} Users</h1></Col>
            <Col  className="title-options mb-2">
                <ul>
                    <li className="pr-3 sty-users-activate">
                        <label className="cursor">Asign To Outlet Group</label>
                    </li>
                    <li className="pr-3 sty-users-create-new">
                        <label className="cursor">Create New</label>
                    </li>
                    <li className="pr-3">
                        <CSVLink 
                          data={filterTable == null ? devicesList : filterTable} 
                          headers={headers} 
                          className="bx bx-cloud-download sty-downloadTable"
                          filename={"Solkos-DevicesList.csv"}
                        />
                    </li>
                    <li>
                        <div className="search-box">
                          <i className="bx bx-search sty-users-search"></i>
                          <Input
                            className="searchInput"
                            placeholder={t("common.search")}
                            onChange={(e) => search(e.target.value)}
                          />
                        </div>
                    </li>
                    
                </ul>
              
              
              
              
            </Col>
            
          </Row>

          <Spin spinning={loading} delay={500} indicator={antLoading}>
          <Form form={form} component={false}>
            <Table
              dataSource={filterTable == null ? devicesList : filterTable}
              columns={mergedColumns }
              rowClassName="editable-rows"
              pagination={{
                position: ['topRight'],
                pageSize: 10
              }}
              
              size="small" //Quizas comentar
              onRow={(record, rowIndex) => {
                console.log(record);
                return {
                  // onClick: event => {}, // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {return false}, // right button click row
                  onMouseEnter: event => { event.currentTarget.classList.add("active") }, // mouse enter row
                  onMouseLeave: event => { event.currentTarget.classList.remove("active") }, // mouse leave row
                };
              }}
            />
          </Form>
          </Spin>
          
          
        </div>
    )
}

export default withRouter(Users)