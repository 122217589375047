import React, { Component } from "react";
import { message, Spin, Table, Button, Popconfirm, Row, Col, Upload } from "antd";
import { ExcelRenderer } from "react-excel-renderer"; 
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./styles.css";

export default class  uploadFileFromClient extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_file: false, 
      cols: [],
      rows: [],
      errorMessage: null,
      columns: [
        {
          title: "Brand Id",
          dataIndex: "brand_id",
          editable: false,
        },
        {
          title: "Marca",
          dataIndex: "customer_id",
          editable: false,
        },
        {
          title: "Numero de Serie",
          dataIndex: "cooler_id",
          editable: false,
        },
        {
          title: "Numero de Serie Marca",
          dataIndex: "cooler_ref",
          editable: false,
        },
        {
          title: "Id Negocio",
          dataIndex: "outlet_id",
          editable: false,
        },
        {
          title: "Nombre Negocio",
          dataIndex: "outlet_name",
          editable: false,
        },
        {
          title: "Dirección Negocio",
          dataIndex: "outlet_address",
          editable: false,
        },
        {
          title: "Contacto Negocio",
          dataIndex: "business_contact",
          editable: false,
        },
        {
          title: "Telefono Negocio",
          dataIndex: "phone_number",
          editable: false,
        },
        {
          title: "Direccion Regional",
          dataIndex: "region",
          editable: false,
        },
        {
          title: "Gerencia de Zona",
          dataIndex: "management_zone",
          editable: false,
        }, 
        {
          title: "Tipo Negocio",
          dataIndex: "outlet_class",
          editable: false,
        },
        {
          title: "Venta Ultimo Mes",
          dataIndex: "last_month_sale",
          editable: false,
        },
        {
          title: "Meses Consecutivos Sin Venta",
          dataIndex: "months_without_sale",
          editable: false,
        },
        {
          title: "Venta Promedio Trimestre",
          dataIndex: "sales_avg_trim",
          editable: false,
        }, 
        {
          title: "Ruta",
          dataIndex: "route",
          editable: false,
        },
        {
          title: "Latitud",
          dataIndex: "latitude",
          editable: false,
        },
        {
          title: "Longitud",
          dataIndex: "longitude",
          editable: false,
        },
        {
          title: "GCS",
          dataIndex: "gcs",
          editable: false,
        }, 
        {
          title: "Condición",
          dataIndex: "conditions",
          editable: false,
        },
        {
          title: "Canal Negocio",
          dataIndex: "channel",
          editable: false,
        },
      ],
    };
  }

  handleSave = (row) => {
    const newData = [...this.state.rows];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = "";
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === "application/vnd.ms-excel" ||
      file[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "Solo se admiten archivos de Excel";
    }
    console.log("file", file[0].type);
    //const isLt2M = file[0].size / 1024 / 1024 < 2;
    //if (!isLt2M) {
    //  errorMessage = "File must be smaller than 2MB!";
    //}
    console.log("errorMessage", errorMessage);
    return errorMessage;
  }

  fileHandler = (fileList) => {
    //console.log("fileList", fileList);
    let fileObj = fileList;
    this.setState({ rows: [] });
    if (!fileObj) {
      this.setState({
        errorMessage: "No se selecciono el archivo",
      });
      return false;
    }
    console.log("fileObj.type:", fileObj.type);
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      this.setState({
        errorMessage:
          "Archivo con formato desconocido, solo archivos de Excel son admitidos.",
      });
      return false;
    }
    this.setState({loading_file: true})
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== "undefined") {
            newRows.push({
              key: index,
              brand_id: row[0],
              customer_id: row[1],
              cooler_id: row[2],
              cooler_ref: row[3],
              outlet_id: row[4],
              outlet_name: row[5],
              outlet_address: row[6],
              business_contact: row[7],
              phone_number: row[8],
              region: row[9],
              management_zone: row[10],
              outlet_class: row[11],
              last_month_sale: row[12],
              months_without_sale: row[13],
              sales_avg_trim: row[14],
              route: row[15],
              latitude: row[16],
              longitude: row[17],
              gcs: row[18],
              conditions: row[19], 
              channel: row[20],
              channel_desc: row[21],
              //status: row[22],
              //transmission: row[23],
              //coverage: row[24], 
            });
          }
        });
        this.setState({loading_file: false})
        if (newRows.length === 0) {
          this.setState({
            errorMessage: "No se encontraron registros en el archivo",
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {  
    let json_aux = [];
    let json_obj;
    let cont = 0;
    let cont_lenght = this.state.rows.length;

    this.setState({loading : true});

    //Se prepara la tabla

    const external_t = await fetch(
      "https://us-central1-imberalink-238816.cloudfunctions.net/solkos-external-client-t",
      {
        method: "POST",  
        mode: "cors",  
        cache: "no-cache", 
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", 
        referrerPolicy: "no-referrer",  
        body: "", 
      }
    )
    .catch((error)=> {
      message.error('e trun')
    });

    for (let i = 0; i < this.state.rows.length; i++) {
      let obj = this.state.rows[i];
      json_aux.push(obj);
      cont++;
      //se hace el envio cada 10000 registros para no exceder la gcf
      if (cont > 5000) {
        //Se convierte el objeto json a partir de json_aux
        json_obj = JSON.stringify(json_aux); 
        //Se envia a GCP
        const response = await fetch(
          "https://us-central1-imberalink-238816.cloudfunctions.net/solkos-external-client",
          {
            method: "POST",  
            mode: "cors",  
            cache: "no-cache", 
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow", 
            referrerPolicy: "no-referrer",  
            body: json_obj, 
          }
        )
        .catch((error)=> {
          message.error('Error al enviar la información' + i + " de " + cont_lenght)
        });
        let that = this;
        response.text().then(function (text) {
          //alert(text); 
          console.log(i + " de " + cont_lenght);
        });

        //Se inicializan las variables control
        json_aux = [];
        cont = 0;
      }
    }

    //Se envia a GCP
    if (this.state.rows.length < 5000 || json_aux.length > 0){
      json_obj = JSON.stringify(json_aux); 
      const resp = await fetch(
        "https://us-central1-imberalink-238816.cloudfunctions.net/solkos-external-client",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: json_obj, // body data type must match "Content-Type" header
        }
      ).catch((error)=> {
        message.error('Error al enviar la información'  + cont_lenght)
      }); 

      let that = this;

      resp.text().then(function (text) {
        console.log(text);
        that.setState({loading : false}); 
        message.info("Información enviada", 10);
      });
    }else{
      this.setState({loading : false});
      message.info("Información enviada", 10);
    }

    const external_b = await fetch(
      "https://us-central1-imberalink-238816.cloudfunctions.net/solkos-external-client-b",
      {
        method: "POST",  
        mode: "cors",  
        cache: "no-cache", 
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", 
        referrerPolicy: "no-referrer",  
        body: " ", 
      }
    )
    .catch((error)=> {
      message.error('e b')
    });

  };

  handleDelete = (key) => {
    const rows = [...this.state.rows];
    this.setState({ rows: rows.filter((item) => item.key !== key) });
  };
  handleAdd = () => {
    const { count, rows } = this.state;
    const newData = {
      key: count,
    };
    this.setState({
      rows: [newData, ...rows],
      count: count + 1,
    });
  };

  render() { 
    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <>
        <Spin spinning={this.state.loading_file} tip="Cargando archivo" size="large">
        <Spin spinning={this.state.loading} tip="Enviando información..." size="large">
        <h1 className="display-3">Carga de datos del cliente a Solkos</h1> 

        <Row gutter={16}>
          <Col
            span={8}
            align="right"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {this.state.rows.length > 0 && (
              <>
                <Button
                  onClick={this.handleSubmit}
                  size="large"
                  type="primary"
                  style={{ marginBottom: 16 }}
                >
                  Enviar información
                </Button> 
              </>
            )}
          </Col>
        </Row>
        <div>
          <Upload
            name="file"
            beforeUpload={this.fileHandler}
            onRemove={() => this.setState({ rows: [] })}
            multiple={false}
          >
            <Button>
              <UploadOutlined /> Clic para cargar archivo
            </Button>
          </Upload>
        </div>
        <div style={{ marginTop: 20 }}>
          <Table
            //components={components}
            rowClassName={() => "editable-row"}
            dataSource={this.state.rows}
            columns={columns}
            scroll={{ x: 400 }}
          />
        </div>
        </Spin>
        </Spin>
      </>
    );
  }
}
