import React, { useState, useEffect, createContext} from 'react';
import jwtDecode from 'jwt-decode';
import {getAccessTokenApi, getRefreshTokenApi, refreshAccessTokenApi, logout} from '../api/auth';

export const AuthContext = createContext();

export default function AuthProvider(props) {
    const {children} = props;
    const [user, setUser] = useState({
        user:null,
        isLoading: true
    });
    
    useEffect(() => {
        checkUserLogin(setUser)
    }, []);
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
}

async function checkUserLogin(setUser) {
    const accessToken = getAccessTokenApi();

    console.log(accessToken);
    if(!accessToken || accessToken === undefined) {
        const refreshToken = getRefreshTokenApi();

        if(!refreshToken || refreshToken === undefined) {
            logout();
            setUser({
                user: null,
                isLoading: false
            })
        } else {
            await refreshAccessTokenApi(refreshToken);
        }
    } else {
        setUser({
            isLoading: false,
            user: await jwtDecode(accessToken)
        })
    }

}