import { Tooltip, Popconfirm, Tag, Space } from "antd";

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

export const statusArray = [
  "FINE",
  "WARNING",
  "RISK",
  "CRITICAL",
  "DISCONNECTED",
];

export const doorStatusArray = [true, false, undefined];

// Used for CSV downloads
export const headers = [
  { label: "Serial Number", key: "id" },
  { label: "Open", key: "open" },
  { label: "Status", key: "status" },
  { label: "Device Name", key: "name" },
  { label: "Address", key: "dsc1" },
  { label: "Description", key: "dsc2" },
  { label: "operation", key: "status" },
];

export const getColumns = ({
  t,
  isEditing,
  cancel,
  save,
  copyToClipboard,
  editingKey,
  edit,
  commandDevice,
}) => [
  {
    title: "",
    width: "2%",
  },
  {
    title: t("device.table.title.serial-number"),
    dataIndex: "id",
    width: "15%",
    editable: false,
    className: "cursor",
    sortDirections: ["descend", "ascend", "descend"],
    defaultSortOrder: "descend",
    sorter: (a, b) => a.id.localeCompare(b.id),
    render: (text, record) => {
      const editable = isEditing(record);
      return (
        <>
          <div className={`deviceId`}>{text}</div>
          <div className={`editDevice text-center d-none`}>
            {editable ? (
              <span>
                <Popconfirm
                  title={`${t("device.alert.cancel.confirm")}`}
                  onConfirm={cancel}
                >
                  <Tooltip placement="top" title={t("common.cancel")}>
                    <a href="\#">
                      <i className="bx bx-x cancel"></i>
                    </a>
                  </Tooltip>
                </Popconfirm>

                <Tooltip placement="top" title={t("common.save")}>
                  <a
                    className="pl-1 save"
                    href="\#"
                    onClick={() => save(record.id)}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    <i className="bx bx-save"></i>
                  </a>
                </Tooltip>
              </span>
            ) : (
              <>
                <Tooltip placement="top" title={`Copiar`}>
                  <i
                    className="bx bx-copy pr-2"
                    onClick={() => copyToClipboard(record.id)}
                  ></i>
                </Tooltip>
                <Tooltip placement="top" title={t("common.edit")}>
                  <i
                    disabled={editingKey !== ""}
                    className="bx bx-edit-alt pr-3"
                    onClick={() => edit(record)}
                  ></i>
                </Tooltip>
              </>
            )}
          </div>
        </>
      );
    },
  },
  {
    title: t("device.table.title.status"),
    dataIndex: "open",
    width: "8%",
    className: "sty-tagTrack",
    render: (text, record) => {
      return text === true ? (
        <Space size="middle">
          <Tag icon={<CheckCircleOutlined />} color="success" key="text">
            {t("device.tooltip.open")}
          </Tag>
        </Space>
      ) : text === false ? (
        <Space size="middle">
          <Tag icon={<MinusCircleOutlined />} color="error" key="text">
            {t("device.tooltip.close")}
          </Tag>
        </Space>
      ) : (
        <Space size="middle">
          <Tag icon={<ClockCircleOutlined />} color="warning" key="text">
            {t("device.tooltip.waiting")}
          </Tag>
        </Space>
      );
    },
  },
  {
    title: t("device.table.title.alert"),
    dataIndex: "status",
    width: "8%",
    filters: [
      {
        text: t("device.tooltip.alert.fine"),
        value: "FINE",
      },
      {
        text: t("device.tooltip.alert.warning"),
        value: "WARNING",
      },
      {
        text: t("device.tooltip.alert.risk"),
        value: "RISK",
      },
      {
        text: t("device.tooltip.alert.critical"),
        value: "CRITICAL",
      },
      {
        text: t("device.tooltip.alert.disconnected"),
        value: "DISCONNECTED",
      },
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
    className: "sty-tagTrack",
    render: (status, record) => {
      if (status === statusArray[0]) {
        return (
          <Space size="middle">
            <Tag icon={<CheckCircleOutlined />} color="success" key="text">
              {t("device.tooltip.alert.fine")}
            </Tag>
          </Space>
        );
      } else if (status === statusArray[1]) {
        return (
          <Space size="middle">
            <Tag icon={<MinusCircleOutlined />} color="gold" key="text">
              {t("device.tooltip.alert.warning")}
            </Tag>
          </Space>
        );
      } else if (status === statusArray[2]) {
        return (
          <Space size="middle">
            <Tag icon={<ClockCircleOutlined />} color="orange" key="text">
              {t("device.tooltip.alert.risk")}
            </Tag>
          </Space>
        );
      } else if (status === statusArray[3]) {
        return (
          <Space size="middle">
            <Tag icon={<ClockCircleOutlined />} color="error" key="text">
              {t("device.tooltip.alert.critical")}
            </Tag>
          </Space>
        );
      } else if (status === statusArray[4]) {
        return (
          <Space size="middle">
            <Tag icon={<ClockCircleOutlined />} color="default" key="text">
              {t("device.tooltip.alert.disconnected")}
            </Tag>
          </Space>
        );
      }
    },
  },
  {
    title: t("device.table.title.name"),
    dataIndex: "name",
    width: "10%",
    editable: true,
  },
  {
    title: t("device.table.title.address"),
    dataIndex: "dsc1",
    width: "21%",
    editable: true,
  },
  {
    title: t("device.table.title.description"),
    dataIndex: "dsc2",
    width: "21%",
    editable: true,
  },
  {
    title: t("device.table.title.operations"),
    dataIndex: "status",
    align: "center",
    className: "cursor",
    render: (text, record) => {
      return (
        <>
          <div className="sty-operations d-none">
            {text === "locked" ? (
              <Tag disabled className="disabled device-blocked" key="status">
                <i className="bx bx-lock-alt"></i>{" "}
                {t("device.table.waiting.validate")}
              </Tag>
            ) : (
              <>
                <Popconfirm
                  title={t("device.alert.open.confirm")}
                  onConfirm={() => commandDevice(record, "Unlock")}
                >
                  <Tooltip
                    placement="top"
                    title={t("device.tooltip.open.short")}
                  >
                    <i className="actionLock openLock bx bx-lock-open-alt pr-2"></i>
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title={t("device.alert.close.confirm")}
                  onConfirm={() => commandDevice(record, "Lock")}
                >
                  <Tooltip
                    placement="top"
                    title={t("device.tooltip.close.short")}
                  >
                    <i className="actionLock closeLock bx bx-lock-alt"></i>
                  </Tooltip>
                </Popconfirm>
              </>
            )}
          </div>
          <div className="sty-lastUpdate">
            {text === "locked" ? (
              <Tag disabled className="disabled device-blocked" key="status">
                <i className="bx bx-lock-alt"></i>{" "}
                {t("device.table.waiting.validate")}
              </Tag>
            ) : (
              new Date(record.updatedAt).toLocaleString("es-MX", {
                timeZone: "America/Mexico_City",
              })
            )}
          </div>
        </>
      );
    },
  },
];
