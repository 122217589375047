// Layout
import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutBasic from "../layouts/LayoutBasic";

// Admin Pages
import Login from '../views/SignIn';
import AdminHome from "../views/Admin/";  //protected
import AdminBoard from "../views/Admin/Board/"; //protected
import AdminDevices from "../views/Admin/Devices";
import AdminUploadFileFromClient from "../views/Admin/uploadFileFromClient";
import AdminSollatekDevices from "../views/Admin/Sollatek/Devices";
import AdminSollatekAssets from "../views/Admin/Sollatek/Assets";
import AdminSollatekUsers from '../views/Admin/Sollatek/Users';
import AdminSollatekCustomers from '../views/Admin/Sollatek/Customers';
import AdminSollatekOutlets from '../views/Admin/Sollatek/Outlets';

// Pages
import ResetPwd from '../views/SignIn/ResetPwd';
import RedirectLogin from '../views/SignIn/RedirectLogin';


// Other
import Error404 from "../views/Error404";

const routes = [
  {
    path: "/login",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/login",
        component: Login,
        exact: true,
      },
      {
        component: Error404,
      },
    ]
  },
  {
    path: "/dashboard",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/dashboard",
        component: AdminHome,
        exact: true,
      },
      {
        path: "/dashboard/:boardUrl",
        component: AdminBoard,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "/devices",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/devices",
        component: AdminDevices,
        exact: true,
      },
      {
        component: Error404,
      },
    ]
  },
  {
    path: "/upload",
    component: LayoutAdmin,
    exact: false, 
    routes: [
      {
        path: "/upload",
        component: AdminUploadFileFromClient,
        exact: true,
      },{
        component: Error404,
      },
    ]
  },
  {
    path: "/sollatek-assets",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/sollatek-assets",
        component: AdminSollatekAssets,
        exact: true,
      },{
        component: Error404,
      },
    ]
  },
  {
    path: "/sollatek-devices",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/sollatek-devices",
        component: AdminSollatekDevices,
        exact: true,
      },{
        component: Error404,
      },
    ]
  },
  {
    path: "/sollatek-users",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/sollatek-users",
        component: AdminSollatekUsers,
        exact: true,
      },{
        component: Error404,
      },
    ]
  },
  {
    path: "/sollatek-customers",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/sollatek-customers",
        component: AdminSollatekCustomers,
        exact: true,
      },{
        component: Error404,
      },
    ]
  },
  {
    path: "/sollatek-outlets",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/sollatek-outlets",
        component: AdminSollatekOutlets,
        exact: true,
      },{
        component: Error404,
      },
    ]
  },
  {
    path: "/",
    component: LayoutBasic,
    exact: false,
    routes: [
      {
        path: "/",
        component: RedirectLogin,
        exact: true,
      },
      {
        path: "/recovery",
        component: ResetPwd,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
