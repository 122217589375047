import React, {useState} from "react"
import { Tooltip, Form, Popconfirm, InputNumber, Input, Tag , Table, Space, Row, Col, notification, Spin  } from 'antd';
import { withRouter } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { CSVLink } from "react-csv";
import '../../../scss/views/device.scss';
import {useTranslation} from 'react-i18next';
import {ACCESS_TOKEN} from "../../../utils/constants";
import {getColumns, headers, doorStatusArray, statusArray} from "./tableDef";

import { getAllProductsByUserApi, deviceUpdateApi, deleteDeviceExtApi, commandDeviceExtApi, getAllDevicesExtApi, updateStatusDeviceApi } from '../../../api/controller/product.js';

import {
  LoadingOutlined
} from '@ant-design/icons';

// Alert Config
const processAlert = (type, principalMsg, message, className='', placement = 'topLeft' ) => {
  notification[type]({
    message: principalMsg,
    description: message,
    placement,
    duration: 4.5,
    className: 'sty-notificationAlert '+className,
  });
};

// Function to make an editable cells inside the table
const EditableCell = ({
  t,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `${t("device.alert.input.empty")} ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};




const Devices = (prop) => {
    const [t] = useTranslation() 

    const [devicesList, setDevicesList] = useState([]);
    const [filterTable, setFilterTable] = useState(null);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [accessT, setAccessT] = useState('');
    const [cirWifiT, setCirWifi] = useState('');
    const [loading, setLoading] = useState(false);
    
    const delay = 3500;	
    const antLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const isEditing = (record) => record.id === editingKey;

    
    const edit = (record) => {
      form.setFieldsValue({
        name: '',
        dsc1: '',
        dsc2: '',
        ...record,
      });
      setEditingKey(record.id);
    };


    const cancel = () => {
      setEditingKey('');
    };
    

    //Save reccord
    const save = React.useCallback(async(id)=>{
      try {
        const row = await form.validateFields();
        const newData = [...devicesList];
        const index = newData.findIndex((item) => id === item.id);
  
        if (index > -1) {
          const user = await jwtDecode(accessT);
          await deviceUpdateApi({productId:id,row:row,userId:user.id,accessToken:accessT})

          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          setDevicesList(newData);
          setEditingKey('');
          //ACTUALIZAR REGISTRO EN DB deviceUpdateApi(id,row,userId)
          //Ultima actualización junto con operations
        } else {
          newData.push(row);
          setDevicesList(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    },[form, devicesList, accessT])

    //Show the devices list
    const showDevices = React.useCallback(async()=>{
      setLoading(true);
      const userStored = localStorage.getItem(ACCESS_TOKEN);
      const cirToken = localStorage.getItem("cirwifiToken");
      const user = await jwtDecode(userStored);
      let data = await getAllProductsByUserApi({userId:user.id,accessToken:userStored}/* , 12, page */)
      let cir_wifi_devs = await getAllDevicesExtApi({tokenUser:cirToken}/* , 12, page */)
      data = data.map(item => {
        const cir_wifi_dev = cir_wifi_devs.find(dev => dev.serial_number === item.id);
        if (cir_wifi_dev === undefined) {
            const res =  {
              ...item, 
              status: statusArray[Math.floor(Math.random()*statusArray.length)],
              open: doorStatusArray[Math.floor(Math.random()*doorStatusArray.length)],
            };
            return res;
        }
        const res = {
          ...item, 
          open: (cir_wifi_dev.status == undefined ? undefined : (
                    cir_wifi_dev.status.door_status == 'UNLOCKED'? true: false)
            ),
          status: cir_wifi_dev.status == undefined ? statusArray[Math.floor(Math.random()*statusArray.length)] : cir_wifi_dev.status.alert_status
        };
        return res;
      });
      setDevicesList(data)
      setAccessT(userStored)
      setCirWifi(cirToken)
      setLoading(false);
      
    },[/* page, */])

    //To open and lock devices
    const commandDevice = React.useCallback(async(record,command)=>{
      setLoading(true)
      let commandPackage ="";
      let strCommand = ""
      let openStatus = ""
      if(command === "Unlock"){
        commandPackage = "a013a17efe9e7a3f69ec4a4a53b749cd7a60b1"
        strCommand = t("device.tooltip.open.short")
        openStatus = true
      }else{
        commandPackage = "a013a17efe9e7a3f69ec4a4a53b749cd7a60b0"
        strCommand = t("device.tooltip.close.short")
        openStatus = false
      }

      const devicesListApi = await getAllDevicesExtApi({tokenUser:cirWifiT,accessToken:accessT})
      const resDeviceData = devicesListApi.filter(i => i.serial_number === record.id && i.customer_id !== null)
      if(resDeviceData && resDeviceData.length > 0){
        const deviceCommand = await commandDeviceExtApi({tokenUser:cirWifiT,accessToken:accessT,name:command,deviceId:resDeviceData[0].id,package:commandPackage})
        console.log(deviceCommand);
        notification.destroy()
        if (!deviceCommand.code){
          processAlert(
            "error", 
            `${t('device.notification.error.title')}:`, 
            `${t('device.notification.error.action.start')} ${strCommand} ${t('device.notification.error.action.end')}`,
            );
        }

        if(deviceCommand.code === 200){
          processAlert(
            "success", 
            "", 
            `${t('device.notification.success.action')} ${strCommand}`
            );
          const user = await jwtDecode(accessT);
          await updateStatusDeviceApi({productId:record.id,userId:user.id,accessToken:accessT, open: openStatus})
          await showDevices()
          cancel()
        }else{
          processAlert(
            "error", 
            `${t('device.notification.error.title')}:`, 
            `${t('device.notification.error.action.start')} ${strCommand} ${t('device.notification.error.action.reason')} ${deviceCommand.message}`
            );
        }
        setLoading(false)

      }else{
        //alert no existe o no esta asignado error
        setLoading(false)
        processAlert(
          "error", 
          `${t('device.notification.error.title')}:`, 
          t('device.notification.error.action.verify')
          );
      }
      
    },[accessT, cirWifiT, t, showDevices])


    const copyToClipboard = async(id) => {
      // Crea un campo de texto "oculto"
      var aux = document.createElement("input");

      // Asigna el contenido del elemento especificado al valor del campo
      aux.setAttribute("value", id);

      // Añade el campo a la página
      document.body.appendChild(aux);

      // Selecciona el contenido del campo
      aux.select();

      // Copia el texto seleccionado
      const successful = document.execCommand("copy");
      if(successful){
        processAlert(
          "success", 
          "", 
          t('device.notification.success.copy')
          );
      } else {
        processAlert(
          "error", 
          `${t('device.notification.error.title')}:`, 
          t('device.notification.error.copy')
          ); 
      }

      // Elimina el campo de la página
      document.body.removeChild(aux);
    }


    const search = React.useCallback(async(value)=>{

      const timeout = setTimeout(() => {
        
        const tempFilterTable = devicesList.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
  
        setFilterTable(tempFilterTable)

      }, delay);
      return () => clearTimeout(timeout);
      
    },[devicesList])

    const columns = getColumns({
      t, 
      isEditing, 
      cancel, 
      save,
      copyToClipboard,
      editingKey,
      edit,
      commandDevice
    });

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
  
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });


    React.useEffect(() => { 
        showDevices()

    },[showDevices,filterTable])


    return (
        <div>
          <Row justify="space-between pb-4">
            <Col className="title"><h1>{t("device.title.name")}</h1></Col>
            <Col className="sty-devices-search mb-2">
              <ul>
                  <li className="pr-3">
                      <CSVLink 
                        data={filterTable == null ? devicesList : filterTable} 
                        headers={headers} 
                        className="bx bx-cloud-download sty-downloadTable"
                        filename={"Solkos-DevicesList.csv"}
                      />
                  </li>
                  <li>
                      <div className="search-box">
                        <i className="bx bx-search"></i>
                        <Input
                          className="searchInput"
                          placeholder={t("common.search")}
                          onChange={(e) => search(e.target.value)}
                        />
                      </div>
                  </li>
                    
              </ul>
              
            </Col>
            
          </Row>

          <Spin spinning={loading} delay={500} indicator={antLoading}>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: (params) => EditableCell({t, ...params}),
                },
              }}
              dataSource={filterTable == null ? devicesList : filterTable}
              columns={mergedColumns }
              rowClassName="editable-rows"
              pagination={{
                position: ['topRight'],
                onChange: cancel,
                pageSize: 10
              }}
              
              size="small" //Quizas comentar
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: event => { event.currentTarget.classList.add("active") }, // mouse enter row
                  onMouseLeave: event => { event.currentTarget.classList.remove("active") }, // mouse leave row
                };
              }}
            />
          </Form>
          </Spin>
          
          
        </div>
    )
}

export default withRouter(Devices)
