import {BASE_PATH, API_VERSION} from '../config/config';

export async function findEmailByUserId(data) {
    const url = `${BASE_PATH}/${API_VERSION}/email/${data}`;
    const params = {
        method: "GET",
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function findUserIdByEmail(email) {
    const url = `${BASE_PATH}/${API_VERSION}/email/findUser/${email}`;
    const params = {
        method: "GET",
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}