import {BASE_PATH, API_VERSION} from '../config/config';

export async function getActiveCompaniesbyIds(data) {
    const url = `${BASE_PATH}/${API_VERSION}/companies-list/user`;
    const bodyObj = {
        companies: data.dataList
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            "Autorization": data.accessToken
        }
    };
    // console.log(data);
    const response = await fetch(url, params)
    return await response.json()
}