import React, {useState} from "react"
import { Form, Input,  Table, Row, Col, /* notification, */ Spin  } from 'antd';
import { withRouter } from "react-router-dom";
// import jwtDecode from 'jwt-decode';
import { CSVLink } from "react-csv";
import './device.scss';
import {useTranslation} from 'react-i18next';
// import {ACCESS_TOKEN} from "../../../utils/constants";

import { /* getDevicesSollatek, */ devicesData } from '../../../api/controller/sollatek';

import {
    LoadingOutlined
} from '@ant-design/icons';

const Devices = (prop) => {
    const [t] = useTranslation() 

    const [devicesList, setDevicesList] = useState([]);
    const [filterTable, setFilterTable] = useState(null);
    const [form] = Form.useForm();
    // const [editingKey, setEditingKey] = useState('');
    // const [accessT, setAccessT] = useState('');
    // const [cirWifiT, setCirWifi] = useState('');
    const [loading, setLoading] = useState(false);
    
    const delay = 3500;	
    const antLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    /* let processAlert = (className,type, principalMsg, message, placement = 'topLeft') => {
      notification[type]({
        message: principalMsg,
        description: message,
        placement,
        duration: 4.5,
        className: 'sty-notificationAlert '+className,
      });
    }; */



    const showDevices = React.useCallback(async()=>{
        setLoading(true)
        // const userStored = localStorage.getItem(ACCESS_TOKEN);
        // const user = await jwtDecode(userStored);
        // const data = await getDevicesSollatek(/*sollatekToken , 12, page */)
        setDevicesList(devicesData)    
        setLoading(false)
    },[/* page, */])

    const search = React.useCallback(async(value)=>{

      const timeout = setTimeout(() => {
        
        const tempFilterTable = devicesList.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
  
        setFilterTable(tempFilterTable)

      }, delay);
      return () => clearTimeout(timeout);
      
    },[devicesList])

    // Para descargas CSV
    const headers = [
      { label: "Device #", key: "serialNumber" },
      { label: "Assigned To", key: "assignedCustomerName" },
      { label: "Installation Date", key: "modifiedDateTime" },
      { label: "Owner", key: "ownerCustomerName" },
      { label: "Id", key: "id" },
      { label: "Customer Owner Id", key: "assignedCustomerId" },
      { label: "Last Modifier Id", key: "modifiedDateTime" },
      { label: "Last Update On", key: "modifiedDateTime" },
      { label: "Creator Id", key: "ownerCustomerId" },
      { label: "Created On", key: "createdDateTime" },
      { label: "Creator", key: "ownerCustomerName" },
      { label: "IMEI", key: "imei" },
      { label: "Model", key: "deviceModelName" },
      { label: "Vendor", key: "deviceVendorName" },
    ];
    
    const columns = [
      {
        title: '',
        width: '2%',
      },
      {
        title: "Device #",
        dataIndex: 'serialNumber',
        width: '25%',
      },
      {
        title: "Assigned To",
        dataIndex: 'assignedCustomerName',
        width: '25%',
      },
      {
        title: "Installation Date",
        dataIndex: 'createdDateTime',
        width: '24%',
      },
      {
        title: "Owner",
        dataIndex: 'ownerCustomerName',
        width: '24%',
      },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });


    React.useEffect(() => { 
        showDevices()

    },[showDevices,filterTable])


    return (
        <div>
          <Row justify="space-between pb-4">
            <Col className="title"><h1>51 {t("device.title.name")}</h1></Col>
            <Col  className="title-options mb-2">
                <ul>
                    {/* <li className="pr-3">
                        <label>Assign (1)</label>
                    </li>
                    <li className="pr-3">
                        <label>Unpair (1)</label>
                    </li> 
                    <li className="pr-3">
                        <label>Filters</label>
                    </li>*/}
                    <li className="pr-3">
                        <CSVLink 
                          data={filterTable == null ? devicesList : filterTable} 
                          headers={headers} 
                          className="bx bx-cloud-download sty-downloadTable"
                          filename={"Solkos-DevicesList.csv"}
                        />
                    </li>
                    <li>
                        <div className="search-box">
                          <i className="bx bx-search"></i>
                          <Input
                            className="searchInput"
                            placeholder={t("common.search")}
                            onChange={(e) => search(e.target.value)}
                          />
                        </div>
                    </li>
                    
                </ul>
              
              
              
              
            </Col>
            
          </Row>

          <Spin spinning={loading} delay={500} indicator={antLoading}>
          <Form form={form} component={false}>
            <Table
              dataSource={filterTable == null ? devicesList : filterTable}
              columns={mergedColumns }
              rowClassName="editable-rows"
              pagination={{
                position: ['topRight'],
                pageSize: 10
              }}
              
              size="small" //Quizas comentar
              onRow={(record, rowIndex) => {
                console.log(record);
                return {
                  // onClick: event => {}, // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {return false}, // right button click row
                  onMouseEnter: event => { event.currentTarget.classList.add("active") }, // mouse enter row
                  onMouseLeave: event => { event.currentTarget.classList.remove("active") }, // mouse leave row
                };
              }}
            />
          </Form>
          </Spin>
          
          
        </div>
    )
}

export default withRouter(Devices)