import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import "./LayoutBasic.scss";

const LayoutBasic = (props) => {
  const { routes } = props;
  const { /* Footer, */ Content } = Layout;
  
    return (
      <Layout>
        <Content className="site-layout-content">
          <LoadRoutes routes={routes} />
        </Content>
        {/* {<Footer style={{ textAlign: 'center' }}>
          <div>
            &copy; Copyright <strong><span>Solkos</span></strong>. Todos los derechos resrvados
          </div>
        </Footer>} */}
      </Layout>

    );
};

const LoadRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((value, index) => (
        <Route
          key={index}
          path={value.path}
          exact={value.exact}
          component={value.component}
        />
      ))}
    </Switch>
  );
};

export default LayoutBasic;
