import {BASE_PATH, API_VERSION} from '../config/config';

export async function getFeaturesByUserApi(data) {
    const url = `${BASE_PATH}/${API_VERSION}/user-features/${data.userId}`;
    const params = {
        method: "GET",
        headers: {
            "Autorization": data.accessToken
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}