import {BASE_PATH, API_VERSION} from '../config/config';

export async function findPhoneByUserId(data) {
    const url = `${BASE_PATH}/${API_VERSION}/phone/${data}`;
    const params = {
        method: "GET",
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function findUserIdByPhone(phone) {
    const url = `${BASE_PATH}/${API_VERSION}/phone/findUser/${phone}`;
    const params = {
        method: "GET",
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}

export async function updatePhoneStatus(data) {
    const url = `${BASE_PATH}/${API_VERSION}/phone/${data.id}`;
    const params = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };
    console.log(data,url);
    const response = await fetch(url, params)
    return await response.json();
}