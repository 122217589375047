import React from "react";
import { Card, Button, List } from "antd";
import { withRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";
import "../../scss/views/admin.scss";
import { useTranslation } from "react-i18next";
import { ACCESS_TOKEN } from "../../utils/constants";

import { getActiveByRoles } from "../../api/controller/board";
import { getActiveCompaniesbyIds } from "../../api/controller/company";

const Admin = (props) => {
  const { Meta } = Card;
  const [t] = useTranslation();
  const [boardList, setBoardList] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);

  /**
   * Example: const arr = [ {id: 1},{id: 2},{id: 1},{id: 3}, ];
   * input:  removeDuplicates(arr)
   * output: [{id: 1},{id: 2},{id: 3}]
   */
  const removeDuplicates = async (arr) => {
    let result = await arr.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    console.log(result);
    return result;
  };

  /**
   * property can be any property of an object on a array
   * Example: const arr = [ {Name: "example name", Surname: "example surrname"}, ];
   * input:  arr.sort(dynamicSort("Name")); OR arr.sort(dynamicSort("Surname")); OR  arr.sort(dynamicSort("-Surname"));
   * output: arr sorted by property "Name" desc / arr sorted by property "Surname" desc / arr sorted by property "Surname" asc
   */
  const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  const showBoards = React.useCallback(
    async () => {
      const userStored = localStorage.getItem(ACCESS_TOKEN);
      const user = await jwtDecode(userStored);
      const data = await getActiveByRoles(
        { userId: user.id, accessToken: userStored } /* , 12, page */
      );
      setBoardList(data);

      if (data.length > 0) {
        let dataList;
        //Admin role
        if (typeof data[0].company !== "undefined") {
          dataList = await data.map((i) => i.company);
          const sortedCompanies = await dataList.sort(
            dynamicSort("company_dsc")
          );
          const result = await removeDuplicates(sortedCompanies);
          console.log(result);
          setCompanies(result);
          //Common role
        } else {
          dataList = data.map((i) => i.company_id);
          const dataCompanies = await getActiveCompaniesbyIds({
            dataList: dataList,
            accessToken: userStored,
          });
          const sortedCompanies = await dataCompanies.sort(
            dynamicSort("company_dsc")
          );
          console.log(sortedCompanies);
          const result = await removeDuplicates(sortedCompanies);
          console.log(result);
          setCompanies(result);
        }
      }
    },
    [
      /* page, */
    ]
  );

  React.useEffect(() => {
    showBoards();
  }, [showBoards]);
  return (
    <div className="sty-dashboard">
      {companies.length === 0 ? ( //cuando no hay tareas regresa que no hay
        <div
          className="text-center p-h-center fs-1-25"
          style={{ backgroundColor: "#fafafa !important" }}
        >
          {t("dashboard.empty")}
        </div>
      ) : (
        companies.map((company) => (
          <div className="pb-2">
            <h2 className="pl-2 pb-2">{company.company_dsc}</h2>
            <List
              className=""
              grid={{ gutter: 16, column: 4, md: 3, sm: 2, xs: 1 }}
              dataSource={boardList.filter(
                (board) => board.company_id === company.id
              )}
              renderItem={(board) => (
                <List.Item>
                  <Card
                    key={board.id}
                    className={`sty-card-form board${board.id}`}
                    hoverable
                    style={{
                      width: "17rem !important",
                      height: "22rem !important",
                    }}
                    // cover={<img alt="Show board" src={board.picture} />}
                  >
                    {board.url.includes("smart-stock") ? (
                      <>
                        <a
                          href={board.board_url}
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Meta
                            title={board.board_title}
                            description={board.board_dsc}
                            className="sty-card-board"
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <a href={`/dashboard/${board.url}`} className="">
                          <Meta
                            title={board.board_title}
                            description={board.board_dsc}
                            className="sty-card-board"
                          />
                        </a>

                        <Button
                          type="primary pull-right"
                          className="primarySolkos sty-boardButton"
                          onClick={() =>
                            props.history.push("/dashboard/" + board.url)
                          }
                        >
                          {t("dashboard.visualize.btn")}
                        </Button>
                      </>
                    )}
                  </Card>
                </List.Item>
              )}
            />
          </div>
        ))
      )}
    </div>
  );
};
export default withRouter(Admin);
