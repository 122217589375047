import React from 'react';
import {useTranslation} from 'react-i18next';
import TableauReport from 'tableau-react';
import {Row, Col} from 'antd'


const Tableau = (props) => {
    const [t] = useTranslation() 

    //Hooks
    const [token, setToken] = React.useState(null);
    const [isBusy, setBusy] = React.useState(true);

    //Methods

    //Options to tableau
    const options = {
        height: 1328,
        width: 1328,
        hideToolbar: true
    }

    const trustedTicket = React.useCallback(async()=>{
        await fetch("https://us-central1-imberalink-238816.cloudfunctions.net/get-trusted-ticket-cors")
            .then(res => res.text())
            .then((data) => {
                console.log(data);
                setToken(data);
                setBusy(false);
            })
            .catch(console.log);
    },[]);


    React.useEffect(() => { 
        try {

          if (token === null) {
            trustedTicket()
          }

        } catch (error) {
          // processAlert("error", "ERROR:", `Error de servidor`);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.boardInfo,token])

    console.log(props.filters);

    return (
        <>
         { isBusy || props.boardInfo === null ? 
            ( <div className="fs-1-25" style={{ backgroundColor: "#fafafa !important" }}>{t("common.loading")}</div> ) :
            (
              <Row wrap={false}>
                <Col flex="auto" className="tableauReport">
                  <TableauReport
                    url={props.boardInfo.board_url}
                    token={token}
                    options={options}
                    filters={props.filters}
                  /> 
                </Col>
              </Row>
            ) 
          }   
        </>
    )
}

export default Tableau
