import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'boxicons/css/boxicons.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'antd/dist/antd.css'
//import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery.easing/jquery.easing.min.js';
import './assets/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Google Analytics
import  { customGA4Init } from './components/customGA/customGA4.jsx'
 
(async () => {
  //await ga4react.initialize();
  await customGA4Init();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
