import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {auth} from '../../../firebase'
import {useTranslation} from 'react-i18next'
import '../../../scss/components/navbar.scss'
import { Popover, Button, Input, Col, Row } from 'antd';
import {logout} from '../../../api/auth'
import { updateUser2faApi,endSessionFirebase } from '../../../api/controller/user'
import { findEmailByUserId } from '../../../api/controller/user_email'
import { totpSecretApi,totpValidateApi,totpQrApi } from '../../../api/controller/otp'
import {ACCESS_TOKEN} from "../../../utils/constants";
import jwtDecode from 'jwt-decode';


// img resources
import logo from "../../../assets/img/ICONO.png"; 

const Navbar = (props) => {
    const [t] = useTranslation()

    const [popupSession,setPopupSession] = React.useState({visible:false});
    const [qrImage, setQrImage] = React.useState("");
    const [qrVisible, setQrVisible] = React.useState(false);
    const [userId, setUserId] = React.useState("");
    const [token2FA, setToken2FA] = React.useState("");
    const [secret, setSecret] = React.useState("");

    const endSession = () => {
        endSessionFirebase() //cierra sesion en back
        auth.signOut() //En caso de existir cierra sesion en cliente
        logout(); //Elimina localstorage
        props.history.push("/login")
        window.location.reload();
    }

    const showQr = async () => {
      const userStored = localStorage.getItem(ACCESS_TOKEN);
      const user = await jwtDecode(userStored);
      const userEmail = await findEmailByUserId(user.id)
      setUserId(user.id)


      const tempSecret = await totpSecretApi()
      const qruri = await totpQrApi({userEmail:userEmail[0].email,secret:tempSecret.secret})
      setQrImage(qruri)
      setSecret(tempSecret.secret)
      qrVisibleTime()
    };

    const sesionActionButon = React.useCallback(async () => {
      
      try {
        const verifyToken2FA = await totpValidateApi({token:token2FA,secret:secret})
        if(verifyToken2FA.valid === true){
          await updateUser2faApi({secret,userId})
          setQrVisible(false)
          setPopupSession({ visible:false })
          setToken2FA("")
        }

      } catch (error) {
        console.log(error);
        setToken2FA("")
        /* qrVisible(false) */
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId,token2FA,secret,qrVisible]);

    const qrVisibleTime = () => {
      setQrVisible(true)
        setTimeout(function () {
          setQrVisible(false);
          setToken2FA("")
    }, 60000);
    }

    return (
        <>

        <Row justify="space-between" className="navbar header">
          <Col className="pl-3">
            <Link className="header__content cursor" to="/dashboard">
                  <div className="header__content-logo ">
                      <img src={logo} alt="Home" className="img-fluid" />
                  </div>
                  <div className="header__content-brandName">
                      <h1>{t("common.solkos")}</h1>
                  </div>
            </Link>
          </Col>

          <Col className="pr-4">
            <Popover
              placement="bottomRight"
              content={
                <>
                  <ul className="sty-nabSession">
                    <li><span className="cursor sty-qrTitle" onClick={() => showQr()}>QR Code</span></li>
                    <li><a href="/#" onClick={() => endSession()}>{t("nabvar.end.sesion")}</a></li>
                  </ul>
                  <div className={`sty-qr ${!qrVisible?'d-none':''}`}>
                    <div className=""><img alt={t("nabvar.qr.code.title")} src={`${qrImage}`}/></div>
                    <p className="pb-2 sty-qrMessage">{t("nabvar.scann.qr")}</p>
                    <Input
                      type="text"
                      className="form-control sty-inputForm"
                      placeholder={ t("nabvar.send.qr.code")}
                      value={token2FA}
                      onChange={(e) => setToken2FA(e.target.value)}
                      onPressEnter={() => sesionActionButon()}
                    />
                  </div>
                </>
              }
              title={t("navbar.user.session")}
              trigger="click"
              overlayClassName="sty-popoerNavbat"
              visible={popupSession.visible}
              onVisibleChange={(visible) => setPopupSession({ visible })}
            >
                <Button className="header__content-logout-btn">
                   <span className="avatarInitialsHolder"><i className='bx bx-power-off'></i></span>    
                </Button>
            </Popover>
          </Col>
        </Row>



        </>
    )
}

export default withRouter(Navbar)